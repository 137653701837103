import {Brand} from "../../Brand";
import {RightMenu} from "../../../features/admin/components/menu/RightMenu";
import {NavBarAuth} from "../../header/NavBarAuth";
import PlatformList from "../../../features/platform/component/PlatformList";
import * as React from "react";
import {useMobile} from "../../../hooks/useMobile";
import {useEffect, useState} from "react";
import {MainMenu} from "../../MainMenu/MainMenu";
import Burger from "../../header/Burger";
import {AnimatePresence, motion} from "framer-motion";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {isLoginRoute} from "../../../utils/variables";
import {useLocation} from "react-router-dom";

export const Header = ({ auth, isNetworkConfig }) => {
    const [show, setShow] = useState(false);
    const [scroll, setScroll] = useState(false);

    const [currentPlatform, setCurrentPlatform] = useState(false);

    const isMobile = useMobile();

    // Change background header
    const changeBack= () => {
        window.scrollY >= 100 ? setScroll(true) : setScroll(false)
    }

    window.addEventListener('scroll', changeBack );

    // overflow the body
    if (show) {
        document.querySelector('body').style.overflow = 'hidden';
    } else {
        document.querySelector('body').style.overflow = '';
    }

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    useEffect(() => {
        if (currentInfrastructure !== null && currentInfrastructure !== 0) {
            setCurrentPlatform(true)
        } else {
            setCurrentPlatform(false)
        }
    }, [currentInfrastructure, currentPlatform]);

    const location = useLocation();


    return (
            <header className={`flex h-20 items-center justify-center z-20 w-full lg:border-b lg:relative fixed bg-white top-0 left-0 border-zinc-20 shadow-sm`}>
            <div className="container items-center flex flex-col justify-center h-full">
                <div className={`${ !isNetworkConfig ? "w-full" : "wrapper"} flex justify-between items-center py-4 relative h-full`}>
                    <div className={`flex items-center w-full gap-8 ${!auth && "justify-between"}`}>
                        <Brand auth={auth}/>
                        { (isLoginRoute && auth && !currentPlatform) &&
                            <MainMenu
                                show={show}
                                setShow={setShow}
                                isMobile={isMobile}
                                auth={auth}
                            />
                        }
                        { (isLoginRoute && auth && currentPlatform) &&
                            <NavBarAuth
                                show={show}
                                setShow={setShow}
                                isMobile={isMobile}
                            />
                        }

                        { (!auth) &&
                            <MainMenu
                                show={show}
                                setShow={setShow}
                                isMobile={isMobile}
                            />
                        }
                        {isMobile && <Burger setshow={setShow} show={show}/>}

                    </div>

                    { isLoginRoute && auth && <PlatformList/> }
                    { isLoginRoute && auth && <RightMenu currentPlatform={currentPlatform}/> }

                </div>
            </div>
        </header>

    )
}