import {motion} from "framer-motion";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {FiInfo, FiLock, FiUsers} from "react-icons/fi";
import {LoadMore} from "../../../components/button/LoadMore";
import * as React from "react";
import {CustomTooltip} from "./CustomTooltip";

export const ListTeams = () => {
    const data = useContext(CompConfigContext);

    return (
        <div className={`pb-10 min-h-[700px] flex items-start justify-center`}>
            <div className="container">
                <div className="wrapper">
                    <motion.ul
                        className={`mb-20 grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                        initial={{opacity: 0, translateY: 100}}
                        animate={{opacity: 1, translateY: 0}}
                        exit={{opacity: 0, translateY: 100}}
                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                    >
                        {data?.myData?.sort((a,b) => a.name.localeCompare(b.name)).map(item =>
                            <motion.li
                                className={`relative border border-slate-200 flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                title={`See '${item?.name}' role`}
                                whileHover={{scale: 1.025}}
                                transition={{type: "spring", duration: .6, ease: "linear"}}
                                key={item.id}
                            >
                                <div
                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-slate-100 p-4 h-24 flex items-center justify-between cursor-pointer `}
                                    onClick={() => data.handleClickOpenDrawer("UPDATE", item.id)}
                                >
                                    <p
                                        className={`absolute w-full top-0 h-2 left-0`}
                                    ></p>
                                    <div className={`w-full flex items-center gap-2  cursor-pointer`}>
                                        <h3 className={`text-lg font-medium w-full`}>
                                            {item?.name}
                                            <span className={`block text-xs text-slate-500`}>
                                                {(item?.isTeamAll || item?.isTeamNotificationNewProject || item?.isTeamNotificationGeneralTickets) ? 'Default team' : ((item?.isProject ? 'Project team' : 'Custom team'))
                                                }
                                        </span>
                                        </h3>
                                        {(item?.isTeamAll) &&
                                            <span className={'flex flex-wrap justify-end'}><FiLock/></span>
                                        }

                                    </div>
                                </div>
                                <div
                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-white grow flex items-center justify-start`}>
                                    <ul className={`flex flex-col items-start gap-2 divide-y ${data.gridMode ? 'p-8 w-full' : 'py-0 w-11/12 pl-8'}`}>
                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                            <p className="flex items-center gap-2 text-sm">
                                                <span
                                                className="text-lg"
                                                title="users"><FiUsers/></span>Users
                                            </p>
                                            <p className="flex items-center gap-2 text-[#272727] text-sm">
                                                {
                                                    item?.listUserInfras?.length
                                                }
                                                <CustomTooltip
                                                    title={item?.listUserInfras?.map(u => {
                                                            return <p key={u?.id}>{u?.status === "invited" || u?.status === "new" ? u.user?.email : u?.user?.fullName}</p>
                                                        }
                                                    )}
                                                    placement={"top"}
                                                    children={
                                                        <span className="text-lg cursor-help"><FiInfo/></span>
                                                    }>
                                                </CustomTooltip>
                                            </p>

                                        </li>
                                    </ul>
                                </div>
                            </motion.li>
                        )}
                    </motion.ul>

                    <LoadMore
                        dataLoad={4}
                        itemsPerPage={data.itemsPerRow}
                        setItemsPerPage={data.setItemsPerRow}
                        totalItems={data.totalItem}
                    />
                </div>
            </div>
        </div>
    )
}