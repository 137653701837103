import {createContext, useEffect, useState} from "react";
import {getLimitedData} from "../utils/getLimetedData";
import {useNavigate} from "react-router-dom";
import {Loader} from "./loader/Loader";
import {motion} from "framer-motion";
import {Filter} from "../features/network/components/Filter";
import {DrawerTemporary} from "./drawer/DrawerTemporary";


export const CompConfigContext = createContext(null)


export const CompConfig = ({
                               title,
                               titleDrawer,
                               titleDrawerUpdate,
                               data,
                               isLoading,
                               error,
                               totalItem,
                               btntitle,
                               content,
                               typeForm,
                               logoTitle,
                               drawerFormCreate,
                               onDrawerClose,
                               onDrawerOpen,
                               isEdit,
                               searchMethod
                           }) => {

    const [method, setMethod] = useState( null );

    const [itemsPerRow, setItemsPerRow] = useState(8);

    const [searchTerm, setSearchTerm] = useState("");

    const [result, setResult] = useState([]);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const [gridMode, setGridMode] = useState(true);
    const [idSelected, setIdSelected] = useState(null);

    const navigate = useNavigate();

    const myData = getLimitedData(result, itemsPerRow);


    // let totalItems = data?.length;

    const buttonVariants = {
        whileTap: {scale: 0.945},
        whileHover: {scale: 1.045}
    };

    useEffect(() => {
        const res = typeof searchMethod === "function" ? searchMethod(data, searchTerm) : data?.filter(item =>
            item.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setResult(res);
    }, [searchTerm, data]);

    const handleClickPath = (path) => {
        return navigate(`${path}`)
    }

    const handleClickOpenDrawer = (openMethod, id) => {
        if (typeof onDrawerOpen === 'function') {
            onDrawerOpen();
        }
        setMethod(openMethod ?? "CREATE");
        setIdSelected(id);
        setIsOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setIsOpenDrawer(false);
        if (typeof onDrawerClose === 'function') {
            onDrawerClose();
        }
    }

    if (isLoading) return (<Loader content="Loading"/>)

    return (
        <motion.article
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{type: "spring", duration: .5, ease: "easeIn"}}
            className="relative min-h-fit lg:pt-10 pt-8"
        >

            {/* Header network */}
            <div className="flex flex-col items-center justify-center gap-4 w-full relative">
                <div className="container">
                    <div className="wrapper">

                        <div className="flex items-center justify-between mb-4">
                            <div>
                                <h2 className="text-2xl font-medium text-slate-700 leading-1 flex items-center gap-2">
                                    {title}
                                    <span className="text-xl text-slate-400 border border-slate-200 w-10 h-10 flex items-center justify-center rounded-full">{logoTitle}</span>
                                </h2>
                            </div>
                            { isEdit &&
                                <motion.button
                                    className="rounded-full text-white p-4 bg-blue-500 text-sm font-bold flex items-center justify-center"
                                    variants={buttonVariants}
                                    whileTap="whileTap"
                                    whileHover="whileHover"
                                    title={btntitle}
                                    onClick={() => {
                                        handleClickOpenDrawer("CREATE")
                                    }}
                                >{btntitle}
                                </motion.button>
                            }
                        </div>

                        {/* Filter */}
                        <Filter
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            buttonVariants={buttonVariants}
                            setIsOpenDrawer={setIsOpenDrawer}
                            setGridMode={setGridMode}
                            gridMode={gridMode}
                            totalItems={totalItem}
                            limiteData={myData}
                        />
                    </div>
                </div>
            </div>


            <CompConfigContext.Provider value={{ title, totalItem, myData, gridMode, searchTerm, handleClickPath, handleClickOpenDrawer, itemsPerRow, setItemsPerRow, isEdit, setIsOpenDrawer }}>
                { content }
            </CompConfigContext.Provider>


            {/********
             * this my drawer temporary
             * */}
            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                typeForm={typeForm}
                method={method}
                titleDrawer={titleDrawer}
                titleDrawerUpdate={titleDrawerUpdate}
                content={drawerFormCreate}
                idSelected={idSelected}
            />
        </motion.article>
    )
}