import * as React from "react";
import {useContext, useState} from "react";

// Design
import {Box} from "@mui/material";

// Components
import {Loader} from "../../../components/loader/Loader";
import {NavigationTabsWrapper} from "../navigation/NavigationTabsWrapper";
import {NavigationTabPanel, a11yProps} from "../navigation/NavigationTabPanel";
import {DescriptionFormContainer} from "../components/DescriptionFormContainer";

// Hooks
import {
    useFetchDescriptions,
} from "../hooks/useFetchDescriptionData";

// Context
import { LayoutWithInstallationContext } from "../../../components/layouts/LayoutWithInstallation";



export const InstallationsDescriptionContainer = () => {

    // From Context
    const layoutWithInstallationContext = useContext(LayoutWithInstallationContext);
    const { user, currentInfrastructure } = layoutWithInstallationContext;

    // Descriptions Data for one Infrastructure
    const { isDescriptionsDataLoading, descriptionsData, setDescriptionSubmitted } = useFetchDescriptions('installation', currentInfrastructure, user?.token)
    // console.dir(descriptionsData)

    const { listInfrastructureStandardDescription: standardDescriptions, listInfrastructureNetworkDescription: additionalDescriptions } = descriptionsData;

    const additionalDescriptionsWithListTabs = additionalDescriptions?.filter((additional) => additional.listTabs.length !== 0);


    // Vertical Tab Management
    const [standardTabValue, setStandardTabValue] = useState(standardDescriptions?.length !== 0 ? 0 : false);
    const [additionalTabValue, setAdditionalTabValue] = useState(standardDescriptions?.length !== 0 ? false : 0);
    const [descriptionType, setDescriptionType] = useState(standardDescriptions?.length !== 0 ? 'standard' : 'additional');


    const handleChangeStandard = (event, newValue) => {
        setAdditionalTabValue(false)

        setStandardTabValue(newValue);
        setDescriptionType('standard');
    };

    const handleChangeAdditional = (event, newValue) => {
        setStandardTabValue(false);

        setAdditionalTabValue(newValue);
        setDescriptionType('additional');
    };



    return (
        <Box sx={{ display: "flex", flexGrow: '1' }}>

            {(isDescriptionsDataLoading ) && (
                <Loader content={'Loading Installation Descriptions'} />
            )}

            {(!isDescriptionsDataLoading && descriptionsData.length !== 0) && (
                <React.Fragment>

                    {/* Navigation Tabs */}
                    <NavigationTabsWrapper handleChangeStandard={handleChangeStandard}
                                 standardTabValue={standardTabValue}
                                 featureStandardDescriptions={standardDescriptions}

                                 handleChangeAdditional={handleChangeAdditional}
                                 additionalTabValue={additionalTabValue}
                                 featureAdditionalDescriptions={additionalDescriptionsWithListTabs}

                                 descriptionTitle={"Installation descriptions"}
                                 a11yProps={a11yProps}
                    />

                    {/* Navigation Panels Content */}
                    {standardDescriptions?.map((description, index) => (

                        descriptionType === 'standard' && (
                            <NavigationTabPanel key={index}
                                                value={standardTabValue}
                                                index={index}
                            >
                                <DescriptionFormContainer descriptionData={description}

                                                          // Send Request Method
                                                          installation
                                                          setDescriptionSubmitted={setDescriptionSubmitted}
                                />
                            </NavigationTabPanel>
                        )

                    ))}

                    {additionalDescriptionsWithListTabs?.map((description, index) => (

                        descriptionType === 'additional' && (
                            <NavigationTabPanel key={index}
                                                value={additionalTabValue}
                                                index={index}
                            >
                                <DescriptionFormContainer descriptionData={description}

                                                          // Send Request Method
                                                          installation
                                                          setDescriptionSubmitted={setDescriptionSubmitted}
                                />
                            </NavigationTabPanel>
                        )

                    ))}

                </React.Fragment>
            )}

        </Box>
    );
}
