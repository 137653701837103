import {AuthButtons} from "./auth/AuthButtons";
import {NavBar} from "../header/NavBar";
import {isLoginRoute} from "../../utils/variables";

export const MainMenu = ({ show, setShow, isMobile, auth }) => {

    return (
        <div className={`w-full z-20 flex-wrap lg:justify-between justify-center items-center navbar ${show ? 'show' : ''}`}>
            <NavBar show={show} isMobile={isMobile} />
            {isLoginRoute && !auth && <AuthButtons />}
        </div>
    )
}
