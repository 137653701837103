import {useContext, useEffect, useState} from 'react';
import {DrawerTemporaryContext} from "../../../components/drawer/DrawerTemporary";

import useFetchOneForm from "../hook/useFetchOneForm";
import {
    FiFeather,
    FiHexagon,
    FiInbox,
    FiLock,
    FiMaximize,
    FiTerminal,
    FiEdit2, FiTrash, FiPlus, FiArrowLeft,
} from "react-icons/fi";
import {useForm} from "react-hook-form";
import {truncateTextLetter} from "../../../utils/truncateText";
import {toast} from "sonner";
import FormService from "../service/FormService";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Loader} from "../../../components/loader/Loader";
import {motion} from "framer-motion";
import FormInput from "../../network/components/form/FormInput";
import ConfirmDialog from "../../../components/confirm/ConfirmDialog";
import {isInt} from "@fullcalendar/core/internal";
import {useFetchInputTypeByCode} from "../../inputsType/hooks/useFetchInputType";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const OneForm = ({networkId}) => {

    const [openConfirmationForm, setOpenConfirmationForm] = useState(false);
    const [openInputs, setOpenInputs] = useState(false);
    const [inputActive, setInputActive] = useState(null);
    const [methodInput, setMethodInput] = useState("POST");
    const [updateFormIncrement, setUpdateFormIncrement] = useState(0);

    const drawerData = useContext(DrawerTemporaryContext);

    const {inputTypeByCode} = useFetchInputTypeByCode('long_string');

    const {data, isLoading} = useFetchOneForm(drawerData.idSelected, updateFormIncrement);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            isHidden: false,
            isLocked: false,
            name: "",
            position: 1,
            resourceType: null,
            tab: {},
            typeOfForm: "null"
        },
    });

    useEffect(() => {
        if (data) {
            setValue("isHidden", data.isHidden);
            setValue("isLocked", data.isLocked);
            setValue("name", data.name);
            setValue("position", data.position);
            setValue("tab", data.tab ? `/api/tabs/${data.tab.id}` : null);
            setValue("typeOfForm", data.typeOfForm);
            setValue("resourceType", data.resourceType);
        }
    }, [data]);


    useEffect(() => {
        if (drawerData.isOpenDrawer) return setOpenInputs(false);
    }, [drawerData.isOpenDrawer]);

    const user = useSelector(selectUser);
    const token = user.token;

    const formServices = new FormService();

    const handleUpdateInput = () => {
        setUpdateFormIncrement(updateFormIncrement + 1);
        setOpenInputs(false);
    }

    const onSubmit = async (myData) => {

        if (!myData) return toast.message("Ooops! somthing went wrong!");

        myData.position = parseInt(myData.position, 10);
        myData.isHidden = Boolean(myData.isHidden);
        myData.isLocked = Boolean(myData.isLocked);
        myData.typeOfForm = (myData.typeOfForm) ? myData.typeOfForm : null;

        try {
            const res = await formServices.EditForm(myData, data?.id, token);

            if (res) {

                drawerData.setIsOpenDrawer(false);
                drawerData.setSubmitting(true);

                return toast.success("Successfully updated the form!");
            }

        } catch (error) {
            console.error(error);
            return toast.warning("Something went wrong!");
        }

    }

    const handleDeleteForm = async () => {

        if (!data) return toast.message("Something went wrong!");

        try {
            const res = await formServices.DeleteForm(data?.id, token);

            if (!res) {

                drawerData.setIsOpenDrawer(false);
                drawerData.setSubmitting(true);

                return toast.success("Form deleted successfully!");
            }

        } catch (error) {
            console.error(error);
            return toast.warning("Something went wrong!");
        }
    }

    const handleClickOpenInputs = (id) => {

        if (id) {
            setMethodInput("PUT");
            setInputActive(id);
        }

        setOpenInputs(!openInputs);
    }

    const handleClickCreateInput = () => {

        setMethodInput("POST");
        setInputActive(null);

        setOpenInputs(!openInputs);
    }

    if (isLoading) return <Loader/>;

    return (
        <>
            {null === data ?
                (<Loader />) :
                (<div className="flex flex-col w-full min-h-screen gap-10">
                    <div className="flex flex-col gap-4 pt-4">
                        <div>
                            <p className="text-slate-950 font-semibold text-lg w-full flex justify-between">Name</p>
                            <p className="text-slate-500 font-medium text-sm">{data.name}</p>
                        </div>

                        <div >
                            <p className="text-slate-950 font-semibold text-lg w-full flex justify-between">Description</p>
                            <p className="text-slate-500 font-medium text-sm">
                                This page allows you to update your forms with new information and view
                                the list of existing entries, ensuring that all your data is current and easily
                                accessible.
                            </p>
                        </div>

                        <div>
                            <p className="text-md text-slate-600 text-semibold flex gap-2 items-center mb-2">
                                <span className="text-sm text-blue-500"><FiFeather/></span>General</p>
                            <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-2 flex flex-col gap-4">
                                <ul className="flex flex-col gap-4 w-full">
                                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                        <div className="w-1/2">
                                            <label htmlFor="name" className="flex gap-1 items-center"> <span
                                                className="text-xs"><FiHexagon/></span>Name of
                                                form
                                            </label>
                                        </div>
                                        <div className="w-1/2">
                                            <input
                                                type="text"
                                                {...register("name")}
                                                className="border border-slate-200 rounded-sm bg-slate-50 focus:outline-none pl-1"
                                            />
                                        </div>
                                    </li>
                                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                        <div className="w-1/2">
                                            <label htmlFor="position" className="flex items-center gap-1"> <span
                                                className="text-xs"><FiTerminal/></span>
                                                Position
                                            </label>
                                        </div>
                                        <div className="w-1/2 text-blue-500">
                                            <input
                                                type="number"
                                                min="1"
                                                {...register("position")}
                                                className="border border-slate-200 rounded-sm bg-slate-50 w-14 focus:outline-blue-200 pl-1"
                                            />
                                        </div>
                                    </li>
                                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                        <div className="w-1/2">
                                            <label htmlFor="isHidden" className="flex items-center gap-1"> <span
                                                className="text-xs"><FiMaximize/></span>
                                                Visible element
                                            </label>
                                        </div>
                                        <div className="w-1/2 text-blue-500 flex gap-2 items-center">
                                            <label
                                                htmlFor="isHidden"
                                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isHidden") ? "text-orange-400 bg-orange-100 border-orange-300" : "text-green-400 bg-green-100 border-green-300"}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="isHidden"
                                                    value={data.isHidden}
                                                    {...register("isHidden")}
                                                    className="sr-only"
                                                />

                                                <span
                                                    className={`${watch("isHidden") ? "border-orange-400 left-0" : "right-0 border-green-400"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                        <div className="w-1/2">
                                            <label htmlFor="isLocked" className="flex items-center gap-1"> <span
                                                className="text-xs"><FiLock/></span>
                                                Locked element
                                            </label>
                                        </div>
                                        <div className="w-1/2 text-blue-500 flex items-center gap-2">
                                            <label
                                                htmlFor="isLocked"
                                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isLocked") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="isLocked"
                                                    value={data.isLocked}
                                                    {...register("isLocked")}
                                                    className="sr-only"
                                                />

                                                <span
                                                    className={`${watch("isLocked") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                            </label>
                                        </div>
                                    </li>
                                    <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                        <div className="w-1/2">
                                            <label htmlFor="typeOfForm" className="flex items-center gap-1"> <span
                                                className="text-xs"><FiInbox/></span>
                                                Type of Form
                                            </label>
                                        </div>
                                        <div className="w-1/2 flex items-center gap-2">
                                            {typeForm.map(item =>
                                                <label
                                                    key={item.id}
                                                    htmlFor={`typeOfForm-${item.value}`}
                                                    className={`relative cursor-pointer flex items-center gap-2 p-1 rounded-full w-14 border ${String(watch("typeOfForm")) !== String(item.value) ? "border-slate-200 bg-slate-100 text-slate-400" : "border-blue-400 bg-blue-100 text-blue-400"} justify-center text-xs`}
                                                >
                                                    <input
                                                        type="radio"
                                                        id={`typeOfForm-${item.value}`}
                                                        value={item.value}
                                                        className="sr-only"
                                                        {...register("typeOfForm")}
                                                    />
                                                    {item.title}
                                                </label>
                                            )}
                                        </div>
                                    </li>
                                </ul>

                                <div className="flex items-center gap-4 justify-between">
                                    <button
                                        title="Save updated form"
                                        type="submit"
                                        className="bg-blue-400 border border-blue-500 hover:bg-blue-500 transition ease-linear text-slate-50 font-medium rounded-full p-2 w-20"
                                    >Save
                                    </button>

                                    <button
                                        title="Delete form"
                                        type="button"
                                        className="hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 text-slate-50 rounded-full w-8 h-8 flex items-center justify-center transition ease-linear border"
                                        onClick={() => setOpenConfirmationForm(true)}
                                    ><FiTrash/>
                                    </button>

                                    <ConfirmDialog
                                        isOpen={openConfirmationForm}
                                        onClose={() => setOpenConfirmationForm(false)}
                                        setIsOpen={setOpenConfirmationForm}
                                        onAgree={() => handleDeleteForm()}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* List Inputs */}
                    <div className={`h-1/2 grow flex flex-col gap-4 `}>
                        {openInputs &&
                            <motion.div
                                className="bg-white w-[550px] min-h-screen fixed top-0 right-0 z-10 flex flex-col overflow-y-auto gap-10"
                                initial={{opacity: 0, translateX: 100}}
                                animate={{opacity: 1, translateX: 0}}
                                exit={{opacity: 0, translateX: 100}}

                                transition={{type: "spring", duration: .3, ease: "linear"}}
                            >
                                <div className="overflow-auto h-screen">
                                    <div
                                        className="w-full flex items-center justify-center border border-slate-200 border-solid px-8 h-20">
                                        <p className="text-left w-full text-xl font-medium">{methodInput === "POST" ? "Create input" : "Edit Input"}</p>

                                        <button
                                            className="block border border-solid border-slate-700 bg-slate-50 rounded-full p-2"
                                            onClick={handleClickOpenInputs}
                                        >
                                            <FiArrowLeft/>
                                        </button>
                                    </div>

                                    <FormInput idInput={inputActive} method={methodInput} form={data}
                                               handleUpdateInput={handleUpdateInput} defaultInputType={inputTypeByCode}
                                               networkId={networkId}/>
                                </div>
                            </motion.div>
                        }
                        <div className="relative border border-slate-200 p-2 flex justify-between items-center">
                            <p className="text-slate-750 font-semibold text-lg w-full flex justify-between">List
                                inputs</p>

                            <motion.button
                                className="border border-slate-200 rounded-full text-slate-500 w-8 h-8 flex items-center justify-center"

                                onClick={handleClickCreateInput}
                                whileHover={{scale: 1.145}}
                                whileTap={{scale: .945}}
                            >
                                <FiPlus/>
                            </motion.button>
                        </div>

                        <div className="rounded-sm flex flex-col gap-2 relative">
                        {/*<ul className="flex gap-4 items-center">*/}
                            {/*    <li className={`flex gap-4 text-sm font-medium text-slate-600`}>Visible <span*/}
                            {/*        className={`rounded-full text-slate-50 text-medium text-xs flex bg-blue-500 items-center justify-center h-6 w-6`}>{data.listInputs?.length}</span>*/}
                            {/*    </li>*/}
                            {/*    <li className={`flex gap-4 text-sm font-medium text-slate-600`}>Locked <span*/}
                            {/*        className={`rounded-full text-slate-50 text-medium text-xs flex bg-slate-200 items-center justify-center h-6 w-6`}>{data.listInputs?.length}</span>*/}
                            {/*    </li>*/}
                            {/*    <li className={`flex gap-4 text-sm font-medium text-slate-600`}>Hidden <span*/}
                            {/*        className={`rounded-full text-slate-50 text-medium text-xs flex bg-slate-200 items-center justify-center h-6 w-6`}>{data.listInputs?.length}</span>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}

                            <table className="table-auto border-collapse w-full">
                                <thead>
                                <tr className="text-left text-sm font-medium text-slate-700 h-10">
                                    <th className="p-1" title="position">Pos</th>
                                    <th className="p-1">Name</th>
                                    <th className="p-1" title={"description"}>Descr</th>
                                    <th className="p-1">Type</th>
                                </tr>
                                </thead>
                                <tbody>

                                {data.listInputs?.sort((a, b) => a.position - b.position).map((item, index) =>
                                    <tr key={item.id}
                                        className={`group text-xs text-slate-800 text-left h-10 ${index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200'}`}>
                                        <td className="p-1">{item.position}</td>
                                        <td className="p-1">
                                            {truncateTextLetter(item.name, 25)}
                                        </td>
                                        <td className="p-1" dangerouslySetInnerHTML={{ __html: item.description}}></td>
                                        <td className="p-1">{item.inputType?.name}</td>
                                        <td>
                                            <button
                                                className="rounded-full w-6 h-6 flex items-center justify-center border-slate-400 border opacity-0 group-hover:opacity-100"
                                                onClick={() => handleClickOpenInputs(item.id)}
                                               >
                                                <FiEdit2/>
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>)
            }
        </>
    );
};

export default OneForm;


const typeForm = [
    {
        id: 1,
        value: "null",
        title: "Classic"
    },
    {
        id: 2,
        value: "0",
        title: "List"
    }
]