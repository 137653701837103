import {Link, Navigate, useParams} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {
    FiGlobe,
    FiMapPin,
    FiChevronUp, FiChevronDown, FiInfo, FiLayers, FiAtSign, FiPhone, FiArrowUpRight, FiCloudDrizzle
} from "react-icons/fi";

import {CustomBreadcrumbs} from "../../../components/breadcrumb/CustomBreadcrumbs";
import {useFetchOneInstallation} from "../hooks/useFetchOneInstallation";
import {Loader} from "../../../components/loader/Loader";
import {useEffect, useState} from "react";
import {ButtonPpa} from "../../catalog/components/button/ButtonPpa";
import {ramdomImage} from "../../catalog/utils/ramdomImage";
import {ItemDesc} from "../../catalog/components/ItemDesc";
import {truncateTextLetter} from "../../../utils/truncateText";
import {toast} from "sonner";
import {isLoginRoute} from "../../../utils/variables";

export const Installation = () => {
    const { id, idcatalog } = useParams();

    const [activeLeftMenu, setActiveLeftMenu] = useState(1);

    const [sliceNb, setSliceNb] = useState(12)
    const [openKeyword, setOpenKeyword] = useState(false);

    const { data, isLoading, error } = useFetchOneInstallation(idcatalog, id);

    if (error){
        return <Navigate to='/notfound'/>
    }

    const handleEmail = (mailto) => {
        window.location.href = mailto
    }

    const mylinkData = [
        {
            id:3,
            title:"Installation catalog",
            link: "#"
        },
        {
            id:4,
            title:data?.infraName,
            link:"#"
        }
    ]

    const handleClickMenu = (menuId) => {
        if (menuId !== 3) return setActiveLeftMenu(menuId);
    }

    const keywordFilter = data?.keywords?.slice(0, sliceNb);

    const handleKeyword = () => {
        if (!openKeyword) {
            const lengthKey = data?.keywords.length;
            setSliceNb(lengthKey)

            setOpenKeyword(true)
        } else {
            setSliceNb(12);
            setOpenKeyword(false)
        }
    }

    if (isLoading) {
        return <Loader content="Load descriptive page" color="orange" />
    }

    return (
        <AnimatePresence>
                <motion.article
                    id="section"
                    className={`min-h-screen w-full flex lg:flex-row flex-col items-stretch`}

                    initial={{ opacity:0 }}
                    animate={{ opacity:1 }}
                    exit={{ opacity: 0 }}

                    transition={{ type: "spring", duration:.5, ease:"easeIn"}}
                >

                    {/*Left Box*/}
                    <div className="lg:w-2/5 w-full grow py-10 flex items-stretch justify-center gap-4 bg-slate-50 border-r border-slate-300">
                        <div className={`lg:w-9/12 w-11/12 flex flex-col gap-2`}>
                            {/* Title */}
                            <h2 className={`lg:text-3xl text-xl font-medium fontfamilly`}>{data.infraName}</h2>
                            {/* Tagline */}
                            {!data.tagLine ?
                                ""
                                :
                                <p className={`text-sm text-slate-500`}>{data.tagLine}</p>
                            }
                            {/* BreadCrumb */}
                            <div className="order-first"><CustomBreadcrumbs linkData={mylinkData}/></div>

                            {/* Image preview */}
                            <motion.div
                                className=" w-full lg:h-[500px] overflow-hidden my-4"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}

                                transition={{type: "spring", duration: .5, ease: "easeIn"}}
                            >
                                <figure className="lg:h-[500px]">
                                    <img
                                        src={data.mainImage ? `${process.env.REACT_APP_API_URL}${data.mainImage}` : `/uploads/update.jpg`}
                                        alt={`${data.name}`}
                                        className="w-full h-full rounded-lg"/>
                                </figure>
                            </motion.div>

                            {/* description */}
                            {data.description &&
                                <div className={`flex flex-col gap-4 mb-4`}>
                                    <h3 className={`text-center underline`}>Description our service</h3>
                                    <div className="text-slate-700" dangerouslySetInnerHTML={{__html: data.description}}/>
                                </div>
                            }

                            {/* Specifique Infos */}
                            {data.functionalities &&
                                <div className="flex flex-col gap-4 mb-4">
                                    <h3 className={`text-center underline`}>Specific informations</h3>
                                    <div
                                        className={`p-4 bg-slate-50 border border-solid border-slate-200 rounded-xl text-slate-700`}
                                        dangerouslySetInnerHTML={{__html: data.functionalities}}
                                    />

                                </div>
                            }
                        </div>


                    </div>

                    {/* Right Box */}
                    <div className="lg:w-2/5 w-full lg:p-4 flex gap-4 rounded-2xl relative border-l">
                        <div className="lg:fixed right-0 top-0 lg:w-2/5 w-full flex lg:flex-row flex-col gap-0 h-screen">
                            <div className={`bg-white lg:w-1/12 w-full px-8 py-10 grow relative min-h-screen overflow-y-auto`}>
                                { activeLeftMenu === 1 &&
                                    <div className={`lg-w-9/12 w-12/11 flex flex-col items-start gap-8`}>
                                        {data.logo &&
                                            <div className="h-14">
                                                <figure className="h-14">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                                                        alt=""
                                                        className="w-full h-full object-contain"/>
                                                </figure>
                                            </div>
                                        }

                                        {/* General Infos */}
                                        <div className="mb-4 px-4 py-2 bg-white w-full">
                                            <ul className="flex flex-col gap-2 divide-y divide-slate-300">
                                                {data.provider &&
                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <h3 className={`lg:text-xl text-lg font-medium fontfamilly`}>Provider</h3>
                                                        <p className={`text-sm text-slate-700`}>{data.provider}</p>
                                                    </li>
                                                }
                                                {data.institution &&

                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <h3 className={`lg:text-xl text-lg font-medium fontfamilly`}>institution</h3>
                                                        <p className={`text-sm text-slate-700`}>{data.institution}</p>
                                                    </li>
                                                }
                                                {data.location?.city || data.location?.country ?
                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <div className="flex flex-wrap items-start gap-4 mt-4">
                                                            {data.location?.city &&
                                                                <p className={`text-sm text-slate-700 flex items-center gap-2`}>
                                                                    <span
                                                                        className="text-slate-500"><FiMapPin /></span> {data.location.city}
                                                                </p>
                                                            }
                                                            {data.location?.country &&
                                                                <p className={`text-sm text-slate-700 flex items-center gap-2`}>
                                                                    <span
                                                                        className="text-slate-500"><FiGlobe/></span> {data.location.country}
                                                                </p>
                                                            }
                                                        </div>
                                                    </li>
                                                    :
                                                    ""
                                                }
                                            </ul>
                                        </div>

                                        {/*ALL keywords*/}
                                        {!data?.keywords || data.keywords?.length === 0 ?
                                            ""
                                            :
                                            <div className={`flex items-center justify-center w-full min-h-20 bg-none border-dashed border border-slate-300 rounded-xl relative`}>
                                                <ul className="flex flex-wrap gap-2 p-4 w-full">
                                                    {keywordFilter?.map((item) =>
                                                        <li key={item.id}
                                                            className="flex items-center justify-center p-3 h-[20px] bg-slate-100 rounded-2xl text-xs text-gray-500">{item.value}</li>
                                                    )}
                                                </ul>
                                                { data?.keywords.length > 12 &&
                                                    <button onClick={handleKeyword}
                                                            className="border border-solid border-blue-300 bg-blue-50 text-blue-500 text-lg font-bold flex items-center justify-center rounded-full absolute -bottom-2">
                                                        {openKeyword ? <FiChevronUp/> : <FiChevronDown/>}
                                                    </button>
                                                }
                                            </div>
                                        }

                                        <hr/>

                                        {/*Info*/}
                                        {!data.contact && !data.contact?.length === 0 ?
                                            ""
                                            :
                                            <div className={` flex flex-col items-center justify-center w-full min-h-20`}>
                                                <div className="w-full flex flex-col items-start justify-center gap-4 border-radius-xl h-full">

                                                    <div className="flex flex-col gap-0 items-start w-full">
                                                        <div
                                                            className="w-full mb-4 border-b border-slate-500 px-4 py-2">
                                                            {data.contactPublicFirstName || data.contactPublicLastName  ?
                                                                <p className={`lg:text-xl text-lg font-medium fontfamilly`}>{data.contactPublicFirstName} {data.contactPublicLastName}</p>
                                                                :
                                                                ""
                                                            }
                                                            {data.contactPublicPosition &&
                                                                <p className={`text-sm text-slate-700`}>{data.contactPublicPosition}</p>}
                                                        </div>

                                                        <div className="w-full">
                                                            <ul className={`flex flex-col gap-0 divide-y divide-slate-200`}>
                                                                {data.contactPublicEmail &&

                                                                    <li className={`py-2 flex items-center justify-between`}>
                                                                        <p className={`text-sm flex gap-2 items-center  `}>
                                                                            <span className="font-bold text-xs text-slate-500"><FiAtSign/></span>
                                                                            Send an email
                                                                        </p>
                                                                        <Link
                                                                            to={`mailto:${data.contactPublicEmail}`}
                                                                            target="_blank"
                                                                            className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                            title={`Send an email to ${data.contactPublicEmail}`}
                                                                        >
                                                                            {data.contactPublicEmail}
                                                                        </Link>
                                                                    </li>
                                                                }

                                                                {data.contactPublicPhone &&
                                                                    <li className={`py-2 flex items-center justify-between`}>
                                                                        <p
                                                                            className={`text-sm flex gap-2 items-center `}>
                                                                            <span className="font-bold text-xs text-slate-500"><FiPhone/></span>
                                                                            Call us
                                                                        </p>
                                                                        <Link
                                                                            to={`tel:${data.contactPublicPhone}`}
                                                                            target="_blank"
                                                                            className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                            title={`Phone`}
                                                                        >
                                                                            {data.contactPublicPhone}
                                                                        </Link>
                                                                    </li>
                                                                }
                                                                {data.website &&
                                                                    <li className={`py-2 flex items-center justify-between`}>
                                                                        <p className={`text-sm flex gap-2 items-center `}>
                                                                            <span
                                                                                className="font-bold text-xs text-slate-500"><FiGlobe/></span>
                                                                            Visit our website</p>
                                                                        <Link
                                                                            to={data.website}
                                                                            target="_blank"
                                                                            className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                            title={`See the website of ${data.website}`}
                                                                        >
                                                                            { truncateTextLetter(data.website, 35) }
                                                                        </Link>
                                                                    </li>
                                                                }
                                                            </ul>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {data.listService?.length > 0 &&
                                            <div className="border border-slate-100 bg-slate-50 rounded-sm h-auto overflow-hidden w-full flex flex-col">
                                                <div className="h-1/5 py-2 flex items-center justify-center bg-slate-200">
                                                    <p className="lg:text-md text-md text-center font-medium fontfamilly text-slate-700">List of Services <span className="text-xs text-slate-500">({data.listService.length})</span></p>
                                                </div>

                                                <div className="h-1/5 grow relative">
                                                    <ul className="flex flex-col gap-0 h-full divide-y divide-slate-200">
                                                        {data.listService?.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                className="text-sm text-slate-500 text-center p-4"
                                                            >
                                                                <Link
                                                                    to={`/catalog/${id}/services/${item.id}`}
                                                                    target="_blank"
                                                                    title={item.name}
                                                                    className="flex items-center gap-2 text-center justify-center"
                                                                >
                                                                    {truncateTextLetter(item.name, 30)} <span className={'text-xs text-slate-500'}><FiArrowUpRight /></span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                                { activeLeftMenu === 2 && (
                                    <div className="flex w-full">
                                        {data.scientificDomain.length === 0 && data.scientificSubDomain.length === 0 ?
                                            <p className="flex flex-col-reverse text-slate-500 items-center justify-center absolute transform left-1/2 top-1/2 -translate-x-1/2 translate-y-1/2">The specific details are currently unavailable.<span className="text-2xl"><FiCloudDrizzle /></span></p>
                                            :
                                            <ul className="grid lg:grid-cols-2 grid-cols-1 gap-6 w-full">
                                                {data.scientificDomain?.length > 0 && (
                                                    <ItemDesc data={data.scientificDomain} typeInstall
                                                              title="Scientific Domain"/>
                                                )}
                                                {data.scientificSubDomain?.length > 0 && (
                                                    <ItemDesc data={data.scientificSubDomain} typeInstall
                                                              title="Scientific SubDomain"/>
                                                )}
                                            </ul>
                                        }

                                    </div>
                                )}

                            </div>

                            <div className={`bg-slate-100 lg:w-1/12 w-full lg:order-last order-first flex items-stretch justify-center`}>
                                <ul className="flex lg:flex-col lg:items-center w-full lg:relative">
                                    {leftMenu.map(item =>
                                        <li
                                            key={item.id}
                                            className={`flex items-center justify-center cursor-pointer w-full h-14 ${activeLeftMenu === item.id ? "bg-white text-blue-500" : "text-slate-500"} `}
                                            onClick={() => handleClickMenu(item.id)}
                                            title={item.title}
                                        >
                                            <span className="font-bold text-xl">{item.icon}</span>
                                        </li>
                                    ) }
                                    {isLoginRoute &&
                                        <li className="text-slate-50 rounded-full lg:absolute fixed right-4 lg:left-1/2 transform lg:-translate-x-1/2 bottom-4 w-12 h-12">
                                            <ButtonPpa/>
                                        </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </motion.article>
        </AnimatePresence>
    )
}


const leftMenu = [
    {
        id:1,
        icon:<FiInfo />,
        title:"Information of Installtion"
    },
    {
        id:2,
        icon:<FiLayers />,
        title:"Specifique infomation"
    }
]