import {Box, Button} from "@mui/material";
import {useContext} from "react";

import {ReusableFormContext} from "../../../components/reusableform/page/ReusableFinalFormContainer";
import {DescriptionFormContext} from "./DescriptionFormContainer";
import {FormContentStepper} from "../../../components/reusableform/components/navigation/FormContentStepper";
import * as React from "react";
import {FormSpecific} from "../../../components/reusableform/components/contents/FormSpecific";
import {Loader} from "../../../components/loader/Loader";

export const DescriptionFormStructure = ({isRead}) => {

    const descriptionFormContext = useContext(DescriptionFormContext)
    const reusableFormContext = useContext(ReusableFormContext)

    return (
        <>
            <FormContentStepper // DescriptionFormContext
                steps={descriptionFormContext.listTabs}
                validMandatory={descriptionFormContext.validMandatory}

                // ReusableFormContext
                activeStep={reusableFormContext.activeStep}
                setActiveStep={reusableFormContext.setActiveStep}
                missingFields={reusableFormContext.missingFields}
                hideMissingFields={reusableFormContext.hideMissingFields}
                valid={reusableFormContext.valid}
            />

            {(!descriptionFormContext.isLoadingJoinedContent) ? (
                <Box sx={{
                    ...((descriptionFormContext.listTabs.length > 0) ? {
                        display: 'flex',
                    } : {
                        display: 'none',
                    }),
                    height: '100%'
                }}
                >
                    <FormSpecific // DescriptionFormContext
                        listTabs={descriptionFormContext.listTabs}
                        joinedInputs={descriptionFormContext.joinedInputs}
                        joinedForms={descriptionFormContext.joinedForms}
                        selectOptions={descriptionFormContext.selectOptions}

                        // List Type Form
                        setJoinedInputs={descriptionFormContext.setJoinedInputs}
                        setState={descriptionFormContext.setState}

                        // ReusableFormContext
                        activeStep={reusableFormContext.activeStep}
                        preloadServices={reusableFormContext.preloadServices}
                        values={reusableFormContext.values}
                        setMissingFieldsChanges={reusableFormContext.setMissingFieldsChanges}
                        hideMissingFields={reusableFormContext.hideMissingFields}
                        isRead={isRead}
                    />

                    {/*For TESTS*/}
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    className="button next"*/}
                    {/*    sx={{ margin: { xs: '10px 0 0', lg: '0 0 0 auto' },*/}
                    {/*        width: { xs: '100%', lg: 'fit-content' },*/}
                    {/*        position: 'fixed',*/}
                    {/*        right: '0'*/}
                    {/*    }}*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => {*/}
                    {/*        reusableFormContext.values.saveform = true;*/}
                    {/*        reusableFormContext.handleSave(reusableFormContext.values)*/}
                    {/*        // console.log(values)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Save*/}
                    {/*</Button>*/}
                    {/*END For TESTS*/}
                </Box>
            ) : (
                <Loader content="Load description form" hfull />
            )}
        </>
    )
}
