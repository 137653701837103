import {styled} from "@mui/material/styles";
import {InputLabel} from "@mui/material";
import {TextField, Select} from "mui-rff";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from "@mui/material/Autocomplete";

export const CustomizedInputLabel = styled(InputLabel)`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    transform: none;
    margin: 0 0 7px;
    color: rgba(0, 0, 0, 1);
    white-space: normal;

  @media screen and (min-width: 1200px) {
    & {
      font-size: 16px;
      margin: 0 0 15px;
      //white-space: normal;
    }
  }  
`;

export const CustomizedTextField = styled(TextField)`
  background-color: transparent;
  
  input {
    padding: 5px 7px;
    border-radius: 5px;
    border: 1px solid rgba(39, 39, 39, 0.45);
    background-color: transparent;

    @media screen and (min-width: 1200px) {
      & {
        padding: 10px 14px;
      }
    }
  }
  
  .Mui-focused { border-radius: 5px }
  .MuiOutlinedInput-notchedOutline { border-radius: 5px; }
`;

export const CustomizedSelect = styled(Select)`
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgba(39, 39, 39, 0.45);
  padding: 0 20px;
  
  .MuiSelect-select {
    padding: 10px 14px;    
  }
  
  .Mui-focused {
    border-radius: 5px;
  }
`;

export const CustomizedDatePickers = styled(DatePicker)`
  background-color: transparent;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(39, 39, 39, 0.45);
    
  input {
    padding: 5px 7px;    
    background-color: transparent;

    @media screen and (min-width: 1200px) {
      & {
        padding: 10px 14px;
      }
    }
  }

  .Mui-focused { border-radius: 4px }
  .MuiOutlinedInput-notchedOutline { border: none; }
`;


export const CustomizedAutoComplete = styled(Autocomplete)`
  background-color: transparent;
  border-radius: 5px;
  //border: 1px solid rgba(39, 39, 39, 0.45);
  
  .MuiInputBase-root {
    padding: 0 14px;

    @media screen and (min-width: 1200px) {
        min-height: 45px
    }
  }
  .MuiTextField-root {
    //padding: 10px 14px;
    background-color: transparent;
  }
  
  .Mui-focused {
    border-radius: 5px;
  }
  
  fieldset { border-color: rgba(39, 39, 39, 0.6); border-radius: 5px; padding: 0}
`;