import AxiosService from './AxiosService'

const urlDefaultEvent = 'api/events'
const urlListEventDashboard = 'api/events/list/config'
const urlListEventResource = 'api/events/list/resource/userInfra/'
const urlRsousourceEvent = 'api/events/infrastructure/'

class EventService {
    getListEventDashboard(userInfra, token) {
        return AxiosService.getWithToken(
            urlListEventResource + userInfra.currentUserInfra.id,
            token,
            'json'
        )
    }

    getListEventResource(user, resource, token) {
        return AxiosService.getWithToken(
            urlRsousourceEvent + user.id + `?resource.id=${resource.id}`,
            token,
            'json'
        )
    }

    getEvent(eventId, token) {
        return AxiosService.getWithToken(
            urlDefaultEvent + '/' + eventId,
            token,
            'json'
        )
    }

    postEvent(event, token) {
        event['token'] = token
        return AxiosService.postWithToken(urlDefaultEvent, event, 'json')
    }

    putEvent(eventId, event, token) {
        return AxiosService.putWithToken(
            urlDefaultEvent + '/' + eventId,
            event,
            token,
            'json'
        )
    }
    deleteEvent(eventId, token) {
        return AxiosService.deleteWithToken(
            urlDefaultEvent + '/' + eventId,
            token,
            'json'
        )
    }
}

export default EventService
