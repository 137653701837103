import {motion} from "framer-motion";
import {LoadMore} from "../../../components/button/LoadMore";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import * as React from "react";
import {FiExternalLink, FiEye, FiEyeOff, FiSend, FiUser} from "react-icons/fi";
import dayjs from "dayjs";
import {AiOutlineFieldTime} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {FaClipboardList} from "react-icons/fa";



export const ServiceType = () => {
    const data = useContext(CompConfigContext);
    const navigate = useNavigate();

    // TODO Finish see full description
    return (
        <div className="pb-10 min-h-[700px] flex items-start justify-center">
            <div className="container">
                <div className={`wrapper relative flex flex-col items-start gap-4`}>
                    { data.error ?
                        <div className="min-h-[200px] flex items-center justify-center w-full">
                            <p className="text-slate-500 text-center text-md font-bold">Ooops! we have a
                                problem {data.error.message}</p>
                        </div>
                        :
                        <>
                            {!data.myData || data.myData.length === 0 ?

                                <div className="min-h-[200px] flex items-center justify-center w-full">
                                    <p
                                        className="text-slate-500 text-center text-sm"
                                    >
                                        No results
                                    </p>
                                </div>
                                :
                                <>
                                    <motion.ul
                                        className={`grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                                        initial={{opacity: 0, translateY: 100}}
                                        animate={{opacity: 1, translateY: 0}}
                                        exit={{opacity: 0, translateY: 100}}
                                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                                    >
                                        {data.myData.map(item =>
                                            <motion.li
                                                className={`relative border border-slate-200 flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                                title={item.description ? item.description : `See the '${item.name}' service type`}
                                                whileHover={{scale: 1.025}}
                                                transition={{type: "spring", duration: .6, ease: "linear"}}
                                                key={item.id}
                                            >
                                                <div
                                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-slate-100 p-4 h-24 flex items-center justify-between cursor-pointer `}
                                                    onClick={() => data.handleClickOpenDrawer("UPDATE", item.id)}
                                                >
                                                    <div className={` flex items-center gap-2  cursor-pointer`}>
                                                        <h3 className={`text-lg font-medium`}
                                                        >{item.name}</h3>
                                                    </div>
                                                </div>

                                                {/*Bottom Box*/}
                                                <div
                                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-white grow flex items-center justify-start`}>
                                                    <ul className={`flex flex-col items-start gap-2 divide-y ${data.gridMode ? 'p-8 w-full' : 'py-0 w-11/12 pl-8'}`}>
                                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                                            <p className="flex items-center gap-2 text-sm"><span
                                                                className="text-lg"
                                                                title="Managers"><FiUser/></span>Responsable
                                                            </p>
                                                            <span
                                                                className="block text-[#272727] text-sm">
                                                                {item?.responsable.fullName ?? "/"}
                                                            </span>
                                                        </li>
                                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                                            <p className="flex items-center gap-2 text-sm"><span
                                                                className="text-lg"
                                                                title="Last update"><AiOutlineFieldTime/></span>Last
                                                                update
                                                            </p>
                                                            <span
                                                                className="block text-[#272727] text-sm">{dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
                                                        </li>
                                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                                            <p className="flex items-center gap-2 text-sm"><span
                                                                className="text-lg"
                                                                title="Visible"><FiEye/></span>Visible
                                                            </p>
                                                            <span
                                                                className="block text-[#272727]">
                                                                {item.isVisible ?
                                                                    <FiEye className={"text-green-700"}/> :
                                                                    <FiEyeOff className={"text-red-700"}/>}
                                                            </span>
                                                        </li>
                                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                                            <p className="flex items-center gap-2 text-[#272727] text-sm">
                                                                <button
                                                                    onClick={() => {
                                                                        navigate('/config/servicetypes/description/' + item.id);
                                                                    }}
                                                                    type={"button"}>
                                                                    See full description
                                                                </button>
                                                                <FaClipboardList />
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </motion.li>
                                        )}
                                    </motion.ul>

                                    <LoadMore
                                        dataLoad={4}
                                        itemsPerPage={data.itemsPerRow}
                                        setItemsPerPage={data.setItemsPerRow}
                                        totalItems={data.totalItem}
                                    />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}