import React, {useState} from "react";

// Design
import {IoListCircle} from "react-icons/io5";
import {AnimatePresence, motion} from "framer-motion";
import {IconButton} from "@mui/material";

// Custom Functions
import {useMobile} from "../../../../../../hooks/useMobile";


export const ListTypeFormsIcon = () => {

    // List Type Form Icon
    const [listTypeIcon, setListTypeIcon] = useState(false);
    const isMobile = useMobile();

    return (
        <IconButton onMouseEnter={() => setListTypeIcon(true)}
                    onMouseLeave={() => setListTypeIcon(false)}
                    aria-label="Add"
                    sx={{
                        position: 'relative',
                        padding: '0',
                        '&:hover': { background: 'transparent' }
                    }}
                    disableRipple
        >
            <IoListCircle className={"mr-3 text-[32px] text text-blue-600 opacity-75 rounded-full"} />

            <AnimatePresence>
                {(listTypeIcon || isMobile) && (
                    <motion.div initial={{x: 20, opacity: 0}}
                                animate={{x: 34, opacity: 1}}
                                transition={{duration: .2}}
                                exit={{x: 20, opacity: 0}}
                                className={"absolute w-auto top-6 z-10"}
                    >
                        <span className={'font-semibold text-[14px] text-nowrap text-blue-500 bg-white border border-blue-500 rounded-lg py-1 px-2'}>
                            List Type Form
                        </span>
                    </motion.div>
                )}
            </AnimatePresence>
        </IconButton>
    )
}
