import {useParams} from "react-router-dom";
import {useFecthNewsById} from "../hooks/useFecthNewsById";
import { AnimatePresence, motion } from "framer-motion";
import {CustomBackground} from "../utils/CustomBackground";
import {Typography} from "@mui/material";
import {formatDateToCustom} from "../../../utils/CustomDate";
import {CustomImageNews} from "../utils/CustomImageNews";
import { List } from "../components/List"
import {truncateText} from "../../../utils/truncateText";
import {CustomBackButton} from "../../../components/button/CustomBackButton";
import {NotFound} from "../../../pages/NotFound/NotFound";
export const DetailNews = () => {
    const { id } = useParams();

    const { data, isLoading, error } = useFecthNewsById(id);

    if (error) return <NotFound />

    return (
        <AnimatePresence>
            { !isLoading &&
                <motion.section
                    className="min-h-screen"
                    initial={{ opacity: 0 }}
                    transition={{type:"spring", duration:.1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.article
                        className={` bg-white min-h-screen flex flex-col items-center pt-24 mb-10`}
                        // style={{
                        //     background: CustomBackground(data.type)
                        // }}
                    >
                        <div className="container">
                            <div className="wrapper flex flex-wrap justify-center items-strecht gap-14">
                                <div className="lg:w-1/3 w-full grow flex flex-col items-center gap-10">
                                    <div className="flex flex-col gap-8 items-center justify-center max-w-[900px]">
                                        <h2 className="text-slate-950 text-4xl font-semibold text-center"> {data.title} </h2>
                                        <span className="block text-sm text-slate-700 order-first text-center">{formatDateToCustom(data.createdAt)} </span>
                                    </div>


                                    {/*<CustomBackButton title="All news" linktoback="news" />*/}

                                    <div className="mb-5 overflow-hidden rounded-2xl lg:h-[500px] h-[350px]">
                                        <figure className="lg:h-[500px] h-[350px]">
                                            {CustomImageNews(data.type)}
                                        </figure>
                                    </div>

                                    <div className="text-left mt-8 max-w-[900px]" dangerouslySetInnerHTML={{__html: data.content}}/>

                                </div>

                            </div>
                        </div>

                    </motion.article>
                    {/*<article className="min-h-[300px] py-24 bg-white flex items-center justify-center">*/}
                    {/*    <div className="container">*/}
                    {/*        /!*<div className="w-6/12">*!/*/}
                    {/*        <div className="wrapper">*/}
                    {/*            <div className="mt-8" dangerouslySetInnerHTML={{__html: data.content}}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</article>*/}
                    {/*<motion.article*/}
                    {/*    className="min-h-[550px] flex flex-col items-center mb-10"*/}
                    {/*>*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="wrapper flex flex-col min-h-[550px] py-10 border-t-2 border-solid border-slate-200">*/}
                    {/*            <Typography component="h2" variant="h1" align="left" sx={{*/}
                    {/*                maxWidth: {lg: "800px"},*/}
                    {/*                marginBottom: "25px"*/}
                    {/*            }}> Related research</Typography>*/}
                    {/*            <List limit={3} hasPagination={false}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</motion.article>*/}
                </motion.section>


            }
        </AnimatePresence>

    )
}