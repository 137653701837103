import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {reusableFormSlice} from "../../../components/reusableform/store/reusableFormSlice";


export const useFetchFormsDescriptionJoinedInputs = (listTabs, setState) => {

    const [joinedInputs, setJoinedInputs] = useState([]);
    const [joinedForms, setJoinedForms] = useState([]);
    const [isLoadingJoinedContent, setIsLoadingJoinedContent] = useState(true);

    // Descriptions Retrieve Data Status
    const [descriptionRetrieveData, setDescriptionRetrieveData] = useState(false);

    // For SELECTS
    const [selectOptions, setSelectOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            function handleGetFormsDescriptionJoinedInputs(){
                try {

                    setIsLoadingJoinedContent(true);

                    // Redux - Clean updateListTypeFormsVisual in store
                    dispatch(reusableFormSlice.actions.updateListTypeFormsVisual([]));

                    // All Tabs parsing - NO NEED

                    // All Forms parsing and joined
                    const joinedFormsResult = listTabs.flatMap((item) => item.listForms);

                    // Check & Filter Existing Forms in listForms
                    const definedJoinedFormsResult = joinedFormsResult.filter((item) => item !== undefined)

                    // Check & Filter Existing listInputs in Forms
                    const definedJoinedFormsWithListInputs = definedJoinedFormsResult.filter((item) => item.listInputs !== undefined)

                    // Do not display when isHidden: true
                    const onlyVisibleFormsResult = definedJoinedFormsWithListInputs.filter((forms) => !forms.isHidden)

                    setJoinedForms(onlyVisibleFormsResult) /* TO USE FOR FORMS SEPARATION */


                    /****** *********** *****/
                    /****** BASIC FORMS *****/
                    /****** *********** *****/
                    const basicTypeForms = onlyVisibleFormsResult.filter((item) => item.typeOfForm === null);

                    const basicTypeFormsListInputs = basicTypeForms.map((item) => item.listInputs);
                    const allBasicFormsListInputs = [].concat(...basicTypeFormsListInputs);

                    setJoinedInputs(allBasicFormsListInputs); /* TO USE FOR ALL INITIAL INPUTS */


                    /****** *************** *****/
                    /****** LIST TYPE FORMS *****/
                    /****** *************** *****/
                    const listTypeForms = onlyVisibleFormsResult.filter((item) => item.typeOfForm !== null);
                    dispatch(reusableFormSlice.actions.addListTypeForms(listTypeForms))

                    const listTypeFormsListInputs = listTypeForms.map((item) => item.listInputs);
                    const allListTypeFormsListInputs = [].concat(...listTypeFormsListInputs);


                    /****** ****************************** *****/
                    /****** ALL INPUTS present in the form *****/
                    /****** ****************************** *****/
                    // To Get selectOptions
                    const completeFormsListInputs = onlyVisibleFormsResult.map((item) => item.listInputs);
                    const allFormsListInputs = [].concat(...completeFormsListInputs);


                    // For SELECTS
                    const selectsResult = allFormsListInputs.filter((item) => item.select);

                    // Do not display when isHidden: true
                    const onlyVisibleSelectsResult = selectsResult.filter((select) => !select.isHidden)

                    setSelectOptions(onlyVisibleSelectsResult);

                    // setIsLoadingJoinedContent(false);


                    /****** ***************************************** *****/
                    /****** ALL LIST INPUT VALUES present in the form *****/
                    /****** ***************************************** *****/
                    const allBasicFormsListInputValues = allBasicFormsListInputs?.map((joined) => {
                        const joinedListInputValues = joined.listInputValues

                        return (
                            joinedListInputValues.length !== 0 ? joinedListInputValues[0] : { value: '', keyLink: 0, input: '/api/inputs/' + joined.id }
                        )
                    });

                    const allListTypeFormsListInputValues = allListTypeFormsListInputs?.filter((joined) => (
                        joined.listInputValues.length !== 0
                    )).map((joined) => joined.listInputValues);

                    const allTypeFormsListInputValues = allBasicFormsListInputValues.concat(...allListTypeFormsListInputValues);

                    // console.log(allTypeFormsListInputValues)

                    setState(prevState => ({
                        ...prevState,
                        project: {
                            ...prevState.project,
                            // Mandatory deepCopy
                            listInputValues: JSON.parse(JSON.stringify([...allTypeFormsListInputValues]))
                        }
                    }))

                    // List Type Forms - Relaunch retrievedValuesTable()
                    setDescriptionRetrieveData(true);

                    setIsLoadingJoinedContent(false);

                } catch (error) {
                    console.error(error);
                }
            }

            if(listTabs && joinedInputs.length === 0) {
                handleGetFormsDescriptionJoinedInputs();
            }
        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [listTabs]);

    return { joinedInputs, setJoinedInputs, joinedForms, isLoadingJoinedContent, selectOptions, descriptionRetrieveData, setDescriptionRetrieveData };

}