import {motion} from "framer-motion";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {FiInfo, FiSend, FiShield, FiUsers} from "react-icons/fi";
import {LoadMore} from "../../../components/button/LoadMore";
import * as React from "react";

export const ListUsers = () => {
    const data = useContext(CompConfigContext);

    const getStatusName = (status) => {
        switch (status) {
            case "validate":
                return "Validate";
            case "new":
                return "New";
            case "invited":
                return "Invited";
            case "on_hold":
                return "On hold";
            default:
                return "/";
        }
    };
    const getStatusColor = (status) => {
        switch (status) {
            case "validate":
                return "bg-green-200 text-green-500";
            case "new":
                return "bg-blue-200 text-blue-500";
            case "invited":
                return "bg-rose-200 text-rose-500";
            case "on_hold":
                return "bg-orange-200 text-orange-500";
            default:
                return "bg-slate-200 text-slate-500";
        }
    };

    return (
        <div className={`pb-10 min-h-[700px] flex items-start justify-center`}>
            <div className="container">
                <div className="wrapper">
                    <motion.ul
                        className={`mb-20 grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                        initial={{opacity: 0, translateY: 100}}
                        animate={{opacity: 1, translateY: 0}}
                        exit={{opacity: 0, translateY: 100}}
                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                    >
                        {data?.myData?.map(item =>
                            <motion.li
                                className={`relative border border-slate-200 flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                title={item.description ? item.description : `See '${item?.user?.fullName}' user`}
                                whileHover={{scale: 1.025}}
                                transition={{type: "spring", duration: .6, ease: "linear"}}
                                key={item.id}
                            >
                                <div
                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-slate-100 p-4 h-24 flex items-center justify-between cursor-pointer `}
                                    title={`Status: ${getStatusName(item.status)}`}
                                    onClick={() => data.handleClickOpenDrawer("UPDATE", item.id)}
                                >
                                    <p
                                        className={`absolute w-full top-0 h-2 left-0 ${getStatusColor(item.status)}`}
                                    ></p>
                                    <div className={` flex items-center gap-2  cursor-pointer`}>
                                        <h3 className={`text-lg font-medium`}>
                                            {item?.user.fullName}
                                            <span className={`block text-xs text-slate-500`}>{item?.user.email}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div
                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} bg-white grow flex items-center justify-start`}>
                                    <ul className={`flex flex-col items-start gap-2 divide-y ${data.gridMode ? 'p-8 w-full' : 'py-0 w-11/12 pl-8'}`}>
                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                            <p className="flex items-center gap-2 text-sm"><span
                                                className="text-lg"
                                                title="Managers"><FiInfo /></span>Status
                                            </p>
                                            <span
                                                className="block text-[#272727] text-sm">
                                                            {getStatusName(item?.status)}
                                                        </span>
                                        </li>
                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                            <p className="flex items-center gap-2 text-sm"><span
                                                className="text-lg"
                                                title="Managers"><FiShield/></span>Role
                                            </p>
                                            <span
                                                className="block text-[#272727] text-sm">
                                                            {item?.role?.name ?? "/"}
                                                        </span>
                                        </li>
                                        <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                            <p className="flex items-center gap-2 text-sm">
                                                <span
                                                className="text-lg"
                                                title="Managers"><FiUsers/></span>Teams
                                            </p>
                                            <p className="flex items-center gap-2 text-[#272727] text-sm">
                                                {
                                                    item?.listTeams?.filter((t) => !t.isProject).length
                                                }
                                                <span className="text-lg cursor-help" title={item?.listTeams?.filter((t) => !t.isProject).map(t =>
                                                    t.name
                                                ).join(", ")}><FiInfo/></span>
                                            </p>

                                        </li>
                                        {item.status === 'invited' &&
                                            <li className="text-slate-500 flex items-center justify-between w-full h-10">
                                                <p className="flex items-center gap-2 text-[#272727] text-sm">
                                                    <button className="" onClick={() => data.handleClickOpenDrawer("RESEND", item.id)} type={"button"}>
                                                        Send again the invitation
                                                    </button><FiSend/>
                                                </p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </motion.li>
                        )}
                    </motion.ul>

                    <LoadMore
                        dataLoad={4}
                        itemsPerPage={data.itemsPerRow}
                        setItemsPerPage={data.setItemsPerRow}
                        totalItems={data.totalItem}
                    />
                </div>
            </div>
        </div>
    )
}