import {motion} from "framer-motion";
import {LoadMore} from "../../../components/button/LoadMore";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {AssessmentOutlined} from "@mui/icons-material";
import * as React from "react";
import {FiLifeBuoy} from "react-icons/fi";



export const ResourceType = () => {
    // TODO A CONTINUER ICI. Mettre la recherche pour toutes les ressources
    const data = useContext(CompConfigContext);
    const isEdit = data.isEdit ?? false;

    return (
        <div className="pb-10 min-h-[700px] flex items-start justify-center">
            <div className="container">
                <div className={`wrapper relative flex flex-col items-start gap-4`}>
                    { data.error ?
                        <div className="min-h-[200px] flex items-center justify-center w-full">
                            <p className="text-slate-500 text-center text-md font-bold">Ooops! we have a
                                problem {data.error.message}</p>
                        </div>
                        :
                        <>
                            {!data.myData || data.myData.length === 0 ?

                                <div className="min-h-[200px] flex items-center justify-center w-full">
                                    <p
                                        className="text-slate-500 text-center text-sm"
                                    >
                                        No results
                                    </p>
                                </div>
                                :
                                <>
                                    <motion.ul
                                        className={`grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                                        initial={{opacity: 0, translateY: 100}}
                                        animate={{opacity: 1, translateY: 0}}
                                        exit={{opacity: 0, translateY: 100}}
                                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                                    >
                                        {data.myData.map(item =>
                                            <motion.li
                                                className={`cursor-pointer relative border border-slate-200 rounded-2xl overflow-hidden flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                                title={`See the ${item.name} Resource Type`}
                                                whileHover={{scale: 1.025}}
                                                transition={{type: "spring", duration: .6, ease: "linear"}}
                                                key={item.id}

                                                onClick={() => isEdit ? data.handleClickOpenDrawer("UPDATE", item.id) : data.handleClickPath(`/resourcetypes/${item.id}`)}
                                            >
                                                {/*Top Box*/}
                                                {/*<div className={`label-oblique`}*/}
                                                {/*    style={{*/}
                                                {/*        backgroundColor: item.color ?? "",*/}
                                                {/*    }}*/}
                                                {/*></div>*/}

                                                <div
                                                    className={`${data.gridMode ? 'w-full' : 'w-2/12'} h-14 p-4 flex items-center justify-between bg-slate-100`}
                                                    style={{
                                                        backgroundColor: item.color ?? "rgb(59 130 246)",
                                                    }}
                                                    onClick={() => isEdit ? data.handleClickOpenDrawer("UPDATE", item.id) : data.handleClickPath(`/resourcetypes/${item.id}`)}
                                                >
                                                    <h3 className={`text-lg font-medium text-center w-full text-slate-50`}>{item.name}</h3>

                                                </div>

                                                {/*Bottom Box*/}
                                                <div className={`h-20n start flex items-center justify-start`}>
                                                    <div className="text-slate-500 flex items-center justify-between w-full p-4">
                                                        <p className="flex items-center gap-2 text-sm"><span
                                                            className="text-lg"
                                                            title="Infrastructure"><FiLifeBuoy /></span>Nb
                                                            resources
                                                        </p>
                                                        <span
                                                            className="block text-[#272727]">{item.nbListResources} </span>
                                                    </div>
                                                    {/*<ul className={`flex flex-col items-start gap-2 divide-y ${data.gridMode ? 'p-4 w-full' : 'py-0 w-11/12 pl-8'}`}>*/}
                                                    {/*    <li className="text-slate-500 flex items-center justify-between w-full h-10">*/}
                                                    {/*        <p className="flex items-center gap-2 text-sm"><span*/}
                                                    {/*            className="text-lg"*/}
                                                    {/*            title="Infrastructure"><AssessmentOutlined/></span>Nb*/}
                                                    {/*            resources*/}
                                                    {/*        </p>*/}
                                                    {/*        <span*/}
                                                    {/*            className="block text-[#272727]">{item.nbListResources} </span>*/}
                                                    {/*    </li>*/}

                                                    {/*     Buttons*/}
                                                    {/*    {isEdit &&*/}
                                                    {/*        <div className="flex items-center justify-between w-full border-t-gray-300 pt-2">*/}
                                                    {/*            <motion.button*/}
                                                    {/*                className="rounded-full text-blue-500 p-2 bg-blue-50/50 border border-solid border-blue-200 text-sm flex items-center justify-center"*/}
                                                    {/*                variants={{*/}
                                                    {/*                    whileTap: {scale: 0.945},*/}
                                                    {/*                    whileHover: {scale: 1.045}*/}
                                                    {/*                }}*/}
                                                    {/*                whileTap="whileTap"*/}
                                                    {/*                whileHover="whileHover"*/}
                                                    {/*                title="Edit"*/}
                                                    {/*                onClick={() => {*/}
                                                    {/*                    alert("EDIT")*/}
                                                    {/*                    // handleClickOpenDrawer()*/}
                                                    {/*                }}*/}
                                                    {/*            >*/}
                                                    {/*                <span className="text-blue-500 font-bold text-2xl">*/}
                                                    {/*                    <FiEdit/></span> Edit*/}
                                                    {/*            </motion.button>*/}
                                                    {/*        </div>*/}
                                                    {/*    }*/}
                                                    {/*    <li className=" text-slate-500 flex items-center justify-between w-full h-10">*/}
                                                    {/*            <p className="flex items-center gap-2 text-sm"><span*/}
                                                    {/*                className="text-lg"*/}
                                                    {/*                title="Users manager"><LuUsers2/></span>Users*/}
                                                    {/*                manager</p>*/}
                                                    {/*            <span*/}
                                                    {/*                className="block text-[#272727]">{item.users?.length}</span>*/}
                                                    {/*        </li>*/}

                                                    {/*</ul>*/}
                                                </div>
                                            </motion.li>
                                        )}
                                    </motion.ul>

                                    <LoadMore
                                        dataLoad={4}
                                        itemsPerPage={data.itemsPerRow}
                                        setItemsPerPage={data.setItemsPerRow}
                                        totalItems={data.totalItem}
                                    />
                                </>
                            }

                        </>
                    }

                </div>
            </div>
        </div>
    )
}