import {InstallationBox} from "./installation/InstallationBox";
import {ProjectBox} from "./project/ProjectBox";
import {ServiceBox} from "./service/ServiceBox";
import {SelectBox} from "./select/SelectBox";
import {SettingBox} from "./setting/SettingBox";
import {useFetchNetworkDescription} from "../../hooks/useFetchNetworkDescription";


export const ContentBox = ({ activeTabs, mydata, pathLogo, setPathLogo, filters, networkId, setActiveMenu, increment, setIncrement, setLengthofTabFilters }) => {

    const {data, isLoading, fetchNetworkDescription} = useFetchNetworkDescription(mydata.id);

    let element;

    switch (activeTabs) {
        case 1:
            element = <InstallationBox data={data} id={mydata.id} fetchData={fetchNetworkDescription} isLoading={isLoading} filters={filters} networkId={networkId} setLengthofTabFilters={setLengthofTabFilters} />;
            break
        case 2:
            element = <ProjectBox />;
            break
        case 3:
            element = <ServiceBox data={data} id={mydata.id} fetchData={fetchNetworkDescription} isLoading={isLoading} filters={filters} networkId={networkId} setLengthofTabFilters={setLengthofTabFilters} />;
            break
        case 4:
            element = <SelectBox />;
            break
        case null:
            element = <SettingBox data={mydata} setActiveMenu={setActiveMenu} increment={increment} setIncrement={setIncrement} />;
            break
        default:
            element= <InstallationBox networkId={networkId} />;
    }

    return element;
}