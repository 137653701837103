import {Outlet, useNavigate} from "react-router-dom";
import {Header} from "../partials/auth/Header";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../redux/alertAction";
import {createContext, useEffect} from "react";
import {Footer} from "../partials/Footer";

export const LayoutWithInstallationContext = createContext(null)

function LayoutWithInstallation() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    useEffect(() => {

        if (null === user) {
            dispatch(displayMessage("Session expired", "Your session has expired, you must connect again", "error"));
            navigate("/login");
        }

        if (null === currentInfrastructure || 0 === currentInfrastructure) {
            dispatch(displayMessage("Forbidden", "You must select an installations to access to this page", "error"));
            navigate("/");
        }
    }, [selectUser, selectCurrentInfrastructure]);


    return (
        <LayoutWithInstallationContext.Provider value={{user, currentInfrastructure}}>
            <Outlet/>
        </LayoutWithInstallationContext.Provider>
    );
}


export default LayoutWithInstallation