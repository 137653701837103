import {FiChevronDown} from "react-icons/fi";
import {ResourceTypeCard} from "../cards/ResourceTypeCard";
import {ConfigCard} from "../cards/ConfigCard";
import {useState} from "react";
import {ProjectCard} from "../cards/ProjectCard";
import {useFetchUserRole} from "../../hooks/useFetchUserRole";


export const NavBarAuth = ({show, setShow, isMobile}) => {
    const [isOpenProject, setIsOpenProject] = useState(false);
    const [isOpenResource, setIsOpenResource] = useState(false);
    const [isOpenConfig, setIsOpenConfig] = useState(false);

    const role = useFetchUserRole();

    const handleClick = (id) => {
        switch (id) {
            case 2:
                setIsOpenProject(res => res = !isOpenProject);
                break;
            case 3:
                setIsOpenResource(res => res = !isOpenResource);
                break;
            case 4:
                setIsOpenConfig(res => res = !isOpenConfig);
                break;
            default:
                console.log(`Sorry, we are out of ${id}.`);
        }
    }

    const handleClose = (id) => {
        switch (id) {
            case 2:
                setIsOpenProject(res => res = false);
                break;
            case 3:
                setIsOpenResource(res => res = false);
                break;
            case 4:
                setIsOpenConfig(res => res = false);
                break;
            default:
                console.log(`Sorry, we are out of ${id}.`);
        }
    }
    return (
        <ul className="flex gap-4">
            {role &&
                <>
                    {role.canProjects &&
                        <li className="relative">
                            <button className="flex items-center gap-2 text-slate-500" onClick={() => handleClick(2)}
                                    onBlur={() => handleClose(2)}>Projects <span
                                className={`text-slate-700 transition-all ${isOpenProject ? "rotate-180" : "rotate-0"}`}><FiChevronDown/></span>
                            </button>
                            <ProjectCard isOpen={isOpenProject}/>
                        </li>

                    }
                    {role.canResources &&
                        <li className="relative">
                            <button className="flex items-center gap-2 text-slate-500" onClick={() => handleClick(3)}
                                    onBlur={() => handleClose(3)}>Resource <span
                                className={`text-slate-700 transition-all ${isOpenResource ? "rotate-180" : "rotate-0"}`}><FiChevronDown/></span>
                            </button>
                            <ResourceTypeCard isOpen={isOpenResource}/>
                        </li>
                    }
                    {(role.canUsers || role.canTeams || role.canRoles || role.canServiceTypes || role.canResourceTypes || role.canCharters) &&
                        <li className="relative">
                            <button className="flex items-center gap-2 text-slate-500" onClick={() => handleClick(4)}
                                    onBlur={() => handleClose(4)}>Config <span
                                className={`text-slate-700 transition-all ${isOpenConfig ? "rotate-180" : "rotate-0"}`}><FiChevronDown/></span>
                            </button>
                            <ConfigCard role={role} isOpen={isOpenConfig}/>
                        </li>
                    }
                </>
            }
        </ul>
    )
}