import {useRef, useState} from "react";
import { motion } from "framer-motion";
import { UserCardInfos} from "../cards/UserCardInfos";
import {useCloseOnOutsideClick} from "../../hooks/useCloseOnOutsideClick";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {FiShield, FiStar} from 'react-icons/fi'


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const Profil = () => {
    const user = useSelector(selectUser);

    const [openBox, setOpenBox] = useState(false);

    const handleClick = () => {
        setOpenBox(!openBox)
    }

    const profilMenu = useRef(null);
    // Outside Element Click Detection
    useCloseOnOutsideClick(profilMenu, setOpenBox)

    let initials = null;
    if (user) {
        const arrayFullName = user.fullName.split(' ');
        initials = arrayFullName.map(n => n.charAt(0).toUpperCase());
    }

    return (
        <div className="lg:relative lg:w-auto md:w-full md:left-0 md:top-0">

            <motion.button
                className={`shadow-sm border border-slate-200 bg-slate-50 transition h-10 w-10 rounded-full`}
                onClick={ handleClick }
                title="See profil"
                // whileTap={{ scale: 0.97 }}
                // whileHover={{ scale: 1.04 }}
            >
                {initials && <span className="text-slate-500 font-bold" >{initials}</span>}
            </motion.button>

            { user?.isSuperAdministrator &&
                <span title={"Super Administrator"} className={`text-slate-100 bg-blue-500 rounded-full h-4 w-4 flex items-center justify-center text-xs absolute -right-4 -top-2`}><FiShield /></span>
            }

            <UserCardInfos isOpen={openBox} profilMenu={profilMenu} />
        </div>
    )
}