import React, {useEffect, useState} from 'react';
import {Avatar, Typography} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {getInitials} from "../../utils/getInitials";
import {Link, useNavigate} from "react-router-dom";
import {FiHelpCircle, FiLogOut, FiSettings, FiUser, FiX} from "react-icons/fi";
import {useFetchUser} from "../../hooks/useFetchUser";
import {useMobile} from "../../hooks/useMobile";
import {Loader} from "../loader/Loader";
import {useSelector} from "react-redux";
import {useFetchOneUserInfra} from "../../features/user/hook/useFetchUserInfra";
import {createSelector} from "reselect";


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const UserCardInfos = ({isOpen, handle, profilMenu}) => {

    const {userInfos} = useFetchUser();
    const isMobile = useMobile();
    const user = useSelector(selectUser);

    const handleClose = () => {
        return handle()
    }
    let navigate = useNavigate();
    const handleClickLogout = () => {
        navigate('/logout');
    }

    const [isLoading, setIsLoading] = useState(true);
    const [increment, setIncrement] = useState(1);

    const userInfraId = useSelector((state) => state.platformReducers?.userInfra?.currentUserInfra?.id) ?? 0;
    const {userInfra} = useFetchOneUserInfra(userInfraId, user.token, increment, setIsLoading);

    useEffect(() => {
        setIncrement(increment+1);
    }, [userInfraId]);

    return (
        <AnimatePresence>
            {isOpen &&
                <motion.div
                    className={`bg-white lg:w-[273px] w-full min-h-[450px] rounded-xl drop-shadow-md absolute top-0 lg:top-[80px] right-0 flex flex-col items-center overflow-hidden z-20`}
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                    id="user-card-infos"
                    ref={profilMenu}
                >
                    {isMobile && <button onClick={handleClose}
                                         className="absolute right-8 top-10 z-20 rounded-full border border-solid border-slate-300 p-1">
                        <span className="text-sm text-slate-500"><FiX/></span>
                    </button>
                    }

                    {!isLoading ? (
                        <>

                            {/* header* general info */}
                            <div
                                className="h-1/4 w-full p-4 relative flex flex-col border border-slate-100 ">
                                <div className={"flex justify-start items-center gap-4 "}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: "rgb(241 245 249)",
                                            height: "55px",
                                            width: "55px",
                                            border: "1px solid rgb(203 213 225)",
                                            color: "rgb(100 116 139)",
                                            fontWeight: "800"
                                        }}
                                    >{getInitials(userInfos.firstname, userInfos.lastname)}
                                    </Avatar>

                                    <div className="flex flex-wrap overflow-hidden">
                                        <Typography component="p"
                                                    variant="body"
                                        >
                                            {`${userInfos.firstname} ${userInfos.lastname}`}
                                        </Typography>
                                        <Typography component="div" variant="body"
                                                    sx={{
                                                        opacity: ".75",
                                                        width: '100%',
                                                    }}>
                                            <p className={"user-email overflow-x-scroll"}>{`${userInfos.email}`}</p>
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            {/* Middle menu */}
                            <div className="h-2/4 w-full mb-10">
                                <ul className="flex flex-col">
                                    {listLink.map(item =>
                                        <li key={item.id} className="h-12 p-4 hover:bg-slate-50">
                                            <Link to={item.link} title={item.title}
                                                  className="flex items-center gap-2 ">
                                                <span className="text-slate-500">{item.icon}</span>{item.title}
                                            </Link>
                                        </li>
                                    )}
                                </ul>

                            </div>


                                <div className="w-full p-x-4 flex flex-col gap-4">
                                    <hr className="w-3/4 m-auto mb-3"/>
                                    {null !== userInfra &&
                                        <>
                                            <div className="flex flex-col gap-3">
                                                <p className={"text-xs text-slate-500 text-center"}>Connected as
                                                    role {userInfra?.role?.name}</p>
                                                <ul
                                                    className={"text-xs text-slate-500 text-center flex gap-2 justify-center items-center"}>
                                                    {userInfra.listTeams.slice(0,3).map(t =>
                                                        <li key={`teams-${t.id}`}
                                                            className={'border border-slate-400 rounded-full px-1'}>{t.name}</li>
                                                    )}
                                                    {userInfra.listTeams.length > 3 &&
                                                        <button className="rounded-full px-4 cursor-pointer gap-2 text-white bg-blue-100">
                                                             <Link to={'/profil'} title={'See more teams'} className={"text-xs text-slate-500 text-center"}>
                                                                <span className="text-slate-500">More</span>
                                                            </Link>
                                                        </button>
                                                    }
                                                </ul>

                                            </div>
                                            <hr className="w-3/4 m-auto mb-3"/>
                                        </>
                                    }

                                    <div className="flex flex-col gap-2 relative">
                                        <button
                                            title="Log out"
                                            className="flex items-center h-12 rounded-full px-4 cursor-pointer gap-2 absolute top-1/2 left-1/2 translate-x-[-50%] text-white bg-[#DC1458]"
                                            onClick={handleClickLogout}
                                        >
                                            <span><FiLogOut/></span>Log out
                                        </button>
                                    </div>
                                </div>
                        </>
                    ) : (<Loader content="Loading"/>)}
                </motion.div>
            }
        </AnimatePresence>
    )
}

const listLink = [
    {
        id: 1,
        title: "My Account",
        link: "/profil",
        icon: <FiUser/>
    },
    {
        id: 2,
        title: "Settings",
        link: "/settings",
        icon: <FiSettings/>
    },
    {
        id: 3,
        title: "Help",
        link: "/docs",
        icon: <FiHelpCircle/>
    }
]
