export const AboutList = [
    {
        id:1,
        title:"Mastering Project Management Excellence",
        list:["Dedicated project space.", "Task planning.", "Contributor management.", "Electronic laboratory notebook."],
        image:"mastering"
    },
    {
        id:2,
        title:"Resource Mastery: Elevating Research Reliability",
        list:["Maintenance of your equipment", "Related documentation", "Space reservation", "Traceability"],
        image:"resource"
    },
    {
        id:3,
        title:"Enhance your data with easy FAIRization.",
        list:["Use of standard templates", "Collection through project and resource management", "Assistance in entering controlled vocabularies", "Export to RDF format"],
        image:"metadata"
    },
    {
        id:4,
        title:"Working with your networks: Complexity is no longer a problem",
        list:["Multi-service, multi-infrastructure projects", "Online calls for projects", "Project evaluation", "Service description catalogs"],
        image:"networks"
    }
]

export const AboutListDown = [
    {
        id:1,
        title:"Enriching Metadata Descriptions: Enhance the value of your data with effortless FAIRization",
        list:["Use of standard templates", "Collection through project and resource management", "Assistance in entering controlled vocabularies", "Export to RDF format"],
        image:"metadata"
    },
    {
        id:2,
        title:"Working with your networks: Complexity is no longer a problem",
        list:["Multi-service, multi-infrastructure projects", "Online calls for projects", "Project evaluation", "Service description catalogs"],
        image:"networks"
    }
]

export const PartnerList = [
    {
        id:1,
        title:"AnaEE France",
        logo:"AnaEE FRance"
    },
    {
        id:2,
        title:"CNRS",
        logo:"cnrs"
    },
    {
        id:3,
        title:"Inrae",
        logo:"inrae"
    },
    {
        id:4,
        title:"Anr",
        logo:"anr"
    },
    {
        id:5,
        title:"UGA",
        logo:"uga"
    },
    {
        id:6,
        title:"Université de Rennes",
        logo:"udr"
    }
]

export const dataFaq = [
    {
        id:1,
        title:"What is Isia ?",
        description: "ISIA stands for Information System for Infrastructure Administration. It is a software solution for ecosystems research facilities. It includes a Site management module and a Project management module.",
        expandedAcc:"panel1"
    },
    {
        id:2,
        title:"What PPA acronym mean ?",
        description: "Project ProposAl"
    },
    {
        id:3,
        title:"What is a Platform ?",
        description: "A Platform is a Research Facility. It is one of the two core units, beside project, managed by the ISIA software. Multiple Platforms can be located on the same site (for example an ecolabs platform and an analytic plaftorm).",
        expandedAcc:"panel2"
    },
    {
        id:4,
        title:"What is a Resource ?",
        description: "A resource is an equipment, supply, tool, documentation. A user manual for a temperature sensor is an example of resource.",
        expandedAcc:"panel3"
    },
    {
        id:5,
        title:"What is Resource Type ?",
        description: "A resource type is a category of resource. User manual is an example of resource type.",
        expandedAcc:"panel4"
    },
    {
        id:6,
        title:"What is the difference between the catalog of resource and the list of resource ?",
        description: "The list of resource provides all the resources of a platform, while the catalog of resource provides the resources of all platforms.",
        expandedAcc:"panel5"
    },
    {
        id:7,
        title:"Notebook",
        description: "Laboratory digital notebook",
        expandedAcc: "panel6"
    },
    {
        id:8,
        title:"What is a ticket ?",
        description: "Ticket are created by user to open a request or raise an issue in the context of a platform.",
        expandedAcc:"panel7"
    },
    {
        id:9,
        title:"What is a step ?",
        description: "A step is a building block used to make Form and Process.",
        expandedAcc:"panel8"
    },
    {
        id:10,
        title:"What is the difference between admin role and super-admin role ?",
        description: "Admin role is for users. Super-admin role is for developers.",
        expandedAcc:"panel9"
    }
]