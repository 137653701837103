import {Link} from "@mui/material";
import LogoEuropean from "../../assets/images/logo-european.png"
import Cnrs from "../../assets/images/logo-cnrs.svg"

export const Footer = () => {
    return (
        <footer className="h-14 flex flex-col items-end justify-center border-t border-gray-200 z-10 bg-white">
            <div className="container">
                <div className="wrapper flex items-center justify-center xl:flex-row flex-col lg:gap-10 gap-10 lg:justify-between">

                    <ul className="flex flex-wrap items-center justify-center gap-6">
                        <li className="flex flex-col items-center gap-2 ">
                            <p className="text-xs lg:text-sm text-slate-500">
                                <Link
                                    href="/changelog"
                                    underline="none"
                                    color="dark"
                                    sx={{fontSize: {xs: '12px', lg: '14px'}}}
                                >
                                v3.0.3.20241006
                            </Link> © 2013 - 2024 | All right reserved</p>
                        </li>
                    </ul>

                    <div className="flex justify-between items-center order-first">
                        <ul className="flex flex-wrap w-full lg:flex-row lg:justify-center lg:divide-x divide-slate-300">
                            {linkFooter.map(item =>
                                <li key={item.id}
                                    className="text-xs text-slate-500 flex items-center px-4"
                                >
                                    <Link
                                        href={item.link}
                                        target="_blank"
                                        underline="none"
                                        color="dark"
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                            )}
                            <li
                                className="text-xs text-slate-500 flex gap-4 items-center px-4"
                            >
                                <p className="text-slate-700 text-xs">Project funded by:</p>
                                <ul className="flex gap-4 items-center">
                                    <li
                                        className="w-8 h-8 cursor-pointer"
                                        onClick={ () => {
                                            return window.open("https://www.cnrs.fr/", "_blank")
                                        }}
                                        title="Go to CNRS website"
                                    >
                                        <figure className="w-8 h-8">
                                            <img src={ Cnrs } alt="Agroserv Logo" className={`w-full h-full object-cover`}/>
                                        </figure>
                                    </li>
                                    <li className={`w-8 h-8 overflow-hidden`}>
                                        <figure className={`w-8 h-8`}>
                                            <img src={ LogoEuropean } alt="European logo" className={`w-full h-full object-cover`}/>
                                        </figure>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const linkFooter = [
    // {
    //     id: 2,
    //     title: "AnaEE France",
    //     link: "https://www.anaee-france.fr/"
    // },
    {
        id: 3,
        title: "End-User Licence Agreement",
        link: "https://isia.cnrs.fr/uploads/isia_eula.pdf"
    },
    // {
    //     id: 4,
    //     title: "Charters and terms of use",
    //     link: "https://www.anaee-france.fr/"
    // },

]