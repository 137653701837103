import {SearchTerm} from "../../../components/SearchTerm";
import {FiAlignJustify, FiGrid, FiPlus} from "react-icons/fi";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";


export const Filter = ({
   searchTerm,
   setSearchTerm,
   setGridMode,
   gridMode,
   totalItems,
   limiteData
}) => {
    const [list, setList] = useState(false);
    const [sort, setSort] = useState('increasing');

    useEffect(() => {
        handleClickSort()
    }, [])
    const handleClickList = () => {
        setList(true)
        setGridMode(false);
    }

    const handleClickGrid = () => {
        setList(false)

        setGridMode(true);
    }

    const handleClickSort = () => {
        if (sort === "increasing") {
            setSort('descending')
        } else {
            setSort('increasing')
        }
    }

    const handleClickGridMode = () => {
        setGridMode(!gridMode);
    }
    return (
        <div className="w-full flex items-center justify-between">
            <SearchTerm searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeHolder="Type anything to filter" />
            <div className="flex items-center gap-2">
                    { totalItems && <span className="text-slate-400 rounded-full p-2 font-bold text-sm">There are {limiteData?.length} of {totalItems} networks results</span>}
            </div>
        </div>
    )
}