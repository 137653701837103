import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";

// Redux
import {useDispatch} from "react-redux";
import {displayMessage} from "../../../redux/alertAction";

// Design
import {motion} from "framer-motion";
import {Box} from "@mui/material";

// Components
import ReusableFinalFormContainer from "../../../components/reusableform/page/ReusableFinalFormContainer";
import {DescriptionFormStructure} from "./DescriptionFormStructure";
import ScrollTop from "../../../components/ScrollTop";
import {FormSuccessContent} from "../../../components/reusableform/components/contents/items/FormSuccessContent";

// Services
import DescriptionService from "../../../services/DescriptionService";

// Hooks
import {useFetchFormsDescriptionJoinedInputs} from "../hooks/useFetchDescriptionForms";

// Custom Functions
import {dataToArray} from "../../../utils/dataToArray";

// Context
import {LayoutWithInstallationContext} from "../../../components/layouts/LayoutWithInstallation";
import {useLayoutWithInstallationContext} from "../../../hooks/useLayoutWithInstallationContext";

export const DescriptionFormContext = createContext(null)



export const DescriptionFormContainer = ({ descriptionData, installation, service, serviceId, setDescriptionSubmitted, isRead }) => {

    // Working One
    const listTabs = dataToArray(descriptionData.listTabs);

    // Specific Forms Data Management
    // From Context
    const { user, currentInfrastructure } = useLayoutWithInstallationContext();

    const networkTypeDescription = descriptionData.networkId && descriptionData.networkId;
    const typeIdDescription = descriptionData.id && descriptionData.id;

    // Initial State
    const [state, setState] = useState({
        isLoading: false,
        schema: {},
        project: {
            isSent: false,
            isValid: false,
            message: null,
            infrastructure: `/api/infrastructures/${currentInfrastructure}`,
            listInputValues: [],
            listInputValuesToDelete: [],
            standardDescription: !networkTypeDescription ? `/api/standard_descriptions/${descriptionData.id}` : null,
            [installation ? 'networkInfrastructureDescription' : 'networkServiceDescription']: networkTypeDescription ? `/api/network_${ installation ? 'infrastructure' : 'service' }_descriptions/${typeIdDescription}` : null,
            descriptionForm: true,
        },
    });

    /* To have consistent input indexes */
    const { joinedInputs,
            setJoinedInputs,
            joinedForms,
            isLoadingJoinedContent,
            selectOptions,
            descriptionRetrieveData,
            setDescriptionRetrieveData,
    } = useFetchFormsDescriptionJoinedInputs(listTabs, setState);

    // console.dir(joinedForms)
    // console.dir(joinedInputs)
    console.dir(state)


    // Show Success Confirmation Page
    const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);


    /***** Save/Submit *****/
    const dispatch = useDispatch();
    const descriptionService = new DescriptionService();

    /***** Sending Edit Installation Form Request *****/
    const sendingFormRequest = async (values) => {

        // console.log(values)

        let putDescriptionMethod, parametersContent;

        if(installation) {
            putDescriptionMethod = 'putInfrastructureDescription';
            parametersContent = [currentInfrastructure, values, user?.token];
        }

        if(service) {
            putDescriptionMethod = 'putServiceDescription'
            parametersContent = [serviceId, values, user?.token];
        }

        const sendMethod = descriptionService[putDescriptionMethod];

        // Inside form management
        console.log(JSON.stringify(values, 0, 2));

        console.log(sendMethod)

        console.log(parametersContent)

        await sendMethod(...parametersContent).then(data => {

            if (data.status === undefined || data.status === 201) {

                dispatch(displayMessage("Success","The description has been updated","success"));

                setState(prevState => ({
                    ...prevState,
                    project: {
                        ...prevState.project,
                        isSent: true,
                        isValid: true,
                        token: data.token,
                    },
                    isLoading: false,
                }));

                // setShowSubmitSuccess(true);
                setDescriptionSubmitted(true);

            } else {
                // Case error
                setState(prevState => ({
                    ...prevState,
                    project: {
                        isSent: true,
                        isValid: false,
                        message: data.data.detail,
                        listInputValues: [values.listInputValues],
                        infrastructureId: currentInfrastructure,
                        standardDescriptionId: descriptionData.id ?? null,
                        networkDescriptionId: descriptionData.networkId ?? null
                    },
                    isLoading: false,
                }));
                // console.log(data)
            }
        });
    }

    return (
        <motion.article
            id="reusableform"
            className={`flex w-full h-full`}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, delay: .3}}
        >
            <div className="container">

                <div className="wrapper flex flex-col lg:max-w-[996px] ml-auto mr-8 2xl:mx-auto">
                    <Box component={"div"} sx={{width: '100%', height: '100%'}}>

                        <DescriptionFormContext.Provider value={{
                            // FormSpecific
                            listTabs,
                            joinedForms,
                            joinedInputs,
                            selectOptions,

                            // List Type Form
                            setJoinedInputs,
                            setState,

                            // Loadings
                            isLoadingJoinedContent,

                            // LTF Retrieve Description Data Status
                            descriptionRetrieveData,
                            setDescriptionRetrieveData
                        }}>

                            {!showSubmitSuccess ? (
                                <ReusableFinalFormContainer
                                    // Fetching Datas
                                    // networkIdOrigin={networkIdOrigin}
                                    // listInfrastructures={listInfrastructures}

                                    // Form
                                    mainState={state.project}
                                    isLoadingState={state.isLoading}
                                    // validate={validate}

                                    // Submit Form
                                    sendingFormRequest={sendingFormRequest}

                                    // FormSpecific
                                    listTabs={listTabs}
                                    joinedInputs={joinedInputs}

                                    // List Type Form
                                    setState={setState}

                                    // FormContentButtons
                                    validMandatory

                                    // Feature Content
                                    featureEdition={
                                        <DescriptionFormStructure isRead={isRead} />
                                    }

                                    isRead={isRead}

                                    hideCaptcha={true}
                                />
                            ) : (
                                <Box className="h-full lg:pt-32">
                                    <FormSuccessContent />
                                </Box>
                            )}

                        </DescriptionFormContext.Provider>

                    </Box>
                    <ScrollTop/>

                </div>
            </div>
        </motion.article>
    );
}
