import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";
import React from "react";
import {FiUser, FiUserCheck, FiUsers} from "react-icons/fi";
import {FaProjectDiagram} from "react-icons/fa";
import {MdCategory} from "react-icons/md";
import {HiDocumentCheck} from "react-icons/hi2";
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const ConfigCard = ({role, isOpen}) => {

    const user = useSelector(selectUser);

    const topMenu = [
        {
            id: 1,
            title: "Users",
            link: "users",
            icon: <FiUser/>,
            display: (user.isSuperAdministrator || role?.canUsers),
        },
        {
            id: 2,
            title: "Roles",
            link: "roles",
            icon: <FiUserCheck/>,
            display: (user.isSuperAdministrator || role?.canRoles),
        },
        {
            id: 3,
            title: "Teams",
            link: "teams",
            icon: <FiUsers/>,
            display: (user.isSuperAdministrator || role?.canTeams),
        }
    ]

    const bottomMenu = [

        {
            id: 4,
            title: "Resource Types",
            link: "resourceTypes",
            icon: <MdCategory/>,
            display: (user.isSuperAdministrator || role?.canResourceTypes),
        },
        {
            id: 5,
            title: "Service Types",
            link: "servicetypes",
            icon: <FaProjectDiagram/>,
            display: (user.isSuperAdministrator || role?.canServiceTypes),
        },
        {
            id: 6,
            title: "Charters and terms of uses",
            link: "charters",
            icon: <HiDocumentCheck/>,
            display: (user.isSuperAdministrator || role?.canCharters),
        }
    ]

    return (
        <AnimatePresence>
            {isOpen &&
                <motion.div
                    className="bg-white w-72 drop-shadow-md top-[70px] absolute left-0 flex-col items-center rounded-2xl overflow-hidden"
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                >
                    <div
                        className="bg-slate-200 h-14 flex items-center justify-center text-md font-semibold px-10 mb-2">
                        <p className="text-slate-700 text-sm text-center">Config Manager</p>
                    </div>

                    <div className="divide-y divide-slate-200 px-8 flex flex-col gap-8">
                        <ul className="flex flex-col justify-between h-full w-full text-left gap-2">
                            {topMenu.map(item =>
                                <React.Fragment key={'top-menu-' + item.id}>
                                    {item.display &&
                                        <li>
                                            <Link
                                                to={`/config/${item.link}`}
                                                className="w-full h-full flex items-center gap-2 justify-start p-2 text-slate-700 group transition ease-linear hover:bg-slate-100 rounded-full"
                                            > <span
                                                className="text-slate-700 text-xs rounded-full border border-slate-200 transition ease-linear p-2">{item.icon}</span> {item.title}
                                            </Link>
                                        </li>
                                    }
                                </React.Fragment>
                            )}
                        </ul>

                        <ul className="flex flex-col justify-between h-full w-full text-left gap-2 pt-8 pb-4">
                            {bottomMenu.map(item =>
                                <React.Fragment key={'bottom-menu-' + item.id}>
                                    {item.display &&
                                        <li>
                                            <Link
                                                to={`/config/${item.link}`}
                                                className="w-full h-full flex items-center gap-2 justify-start p-2 text-slate-700 group transition ease-linear hover:bg-slate-100 rounded-full"
                                            > <span
                                                className="text-slate-700 text-xs rounded-full border border-slate-200 transition ease-linear p-2">{item.icon}</span> {item.title}
                                            </Link>
                                        </li>
                                }
                                </React.Fragment>
                            )}
                        </ul>
                    </div>

                </motion.div>
            }
        </AnimatePresence>
    )
}
