import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFetchRoleData} from "../hooks/api/useFetchRoleData";
import {Loader} from "../../../components/loader/Loader";
import {FiShield} from "react-icons/fi";
import {useEffect, useState} from "react";
import {CompConfig} from "../../../components/CompConfig";
import * as React from "react";
import {ListRoles} from "../components/ListRoles";
import {RoleForm} from "../components/form/RoleForm";


export function RoleManagementContainer() {
    const [data, setData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [incrementList, setIncrementList] = useState(0);
    const [incrementEdit, setIncrementEdit] = useState(0);

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );

    const user = useSelector(selectUser);

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const infrastructureId = useSelector(selectCurrentInfrastructure);

    const {roles, isLoading, error } =  useFetchRoleData(infrastructureId, user.token, incrementList);

    useEffect(() => {
        if(!isLoading) {
            setData(roles);
            setTotalItem(roles?.length ?? 0);
        }
    }, [isLoading, roles]);

    const onDrawerCloseForm = () => {
        setIncrementList(incrementList + 1);
    }

    const onDrawerOpenForm = () => {
        setIncrementEdit(incrementEdit + 1);
    }

    const searchMethod = (data, searchTerm) => {
        return data?.filter(item => {
                return (item.name?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    ((item?.listUserInfras?.map(u =>
                        (u?.user?.fullName ?? "").toLowerCase()
                    ).join(", ")).includes(searchTerm.toLowerCase())) ||
                    ((item?.listUserInfras?.map(u =>
                        (u.user?.email ?? "").toLowerCase()
                    ).join(", ")).includes(searchTerm.toLowerCase()))
            }
        );
    }

    return (
        undefined === roles ?
            (<Loader/>)
            :
            (<CompConfig
                title={"Manage roles"}
                titleDrawer="Create a new role"
                titleDrawerUpdate="Update a role"
                logoTitle={<FiShield />}
                data={data}
                isLoading={isLoading}
                error={error}
                totalItem={totalItem}
                btntitle="Create a new role"
                content={<ListRoles />}
                typeForm="rolesmanage"
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={onDrawerCloseForm}
                onDrawerOpen={onDrawerOpenForm}
                isEdit={true}
                drawerFormCreate={<RoleForm increment={incrementEdit} />}
                searchMethod={searchMethod}
            />)
    )
}