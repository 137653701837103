import {useEffect, useState} from "react";

import DescriptionService from "../../../services/DescriptionService";
import ResourceService from '../../resource/services/Resource'
const descriptionService = new DescriptionService();
const resourceService = new ResourceService();


export const useFetchStandardDescription = (descriptionType, typeId, userToken) => {

    // Tabs Management
    const [isStandardDescriptionLoading, setIsStandardDescriptionLoading] = useState(false);
    const [stantardDescriptions, setStantardDescriptions] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(typeId) {
                async function handleGetStandardDescription(){
                    try {
                        setIsStandardDescriptionLoading(true);
                        // const response = await descriptionService.getServiceStandardDescriptionsPerService(serviceId, userToken);

                        let response;

                        if (descriptionType === 'service') {
                            response = await descriptionService.getServiceStandardDescriptionsPerService(typeId, userToken);
                        }

                        if (descriptionType === 'installation') {
                            response = await descriptionService.getInfrastructureStandardDescriptionsPerInfra(typeId, userToken);
                        }


                        const hydraMember = response["hydra:member"];
                        // console.log(response)
                        // console.log(hydraMember)

                        setStantardDescriptions(hydraMember)

                        setIsStandardDescriptionLoading(false);

                    } catch (error) {
                        console.error(error);
                        setIsStandardDescriptionLoading(false);
                    }
                }
                handleGetStandardDescription().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [typeId]);

    return { isStandardDescriptionLoading, stantardDescriptions };
}

export const useFetchAdditionalFieldsPerInfra = (descriptionType, infrastructureId, userToken) => {

    // Tabs Management
    const [isAdditionalFieldsLoading, setIsAdditionalFieldsLoading] = useState(false);
    const [additionnalFields, setAdditionalFields] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(infrastructureId) {
                async function handleGetAdditionalFieldsPerInfra(){
                    try {
                        setIsAdditionalFieldsLoading(true);
                        // const response = await descriptionService.getServiceAdditionalFieldsPerInfra(infrastructureId, userToken);

                        let response;

                        if (descriptionType === 'service') {
                            response = await descriptionService.getServiceAdditionalFieldsPerInfra(infrastructureId, userToken);
                        }

                        if (descriptionType === 'installation') {
                            response = await descriptionService.getInfrastructureAdditionalFieldsPerInfra(infrastructureId, userToken);
                        }
                        const hydraMember = response["hydra:member"];
                        // console.log(response)
                        // console.log(hydraMember)

                        setAdditionalFields(hydraMember)

                        setIsAdditionalFieldsLoading(false);

                    } catch (error) {
                        console.error(error);
                        setIsAdditionalFieldsLoading(false);
                    }
                }
                handleGetAdditionalFieldsPerInfra().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [infrastructureId]);

    return { isAdditionalFieldsLoading, additionnalFields };
}


export const useFetchDescriptions = (descriptionType, elementId, userToken) => {

    // Tabs Management
    const [isDescriptionsDataLoading, setIsDescriptionsDataLoading] = useState(false);
    const [descriptionsData, setDescriptionsData] = useState([]);

    const [descriptionSubmitted, setDescriptionSubmitted] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {

            // if(typeId) {
            if(elementId || descriptionSubmitted) {
                async function handleGetDescriptions(){
                    try {
                        // setIsDescriptionsDataLoading(true);
                        !descriptionSubmitted && setIsDescriptionsDataLoading(true);

                        let response;

                        if (descriptionType === 'service') {
                            response = await descriptionService.getServiceDescriptionsPerService(elementId, userToken);
                        }

                        if (descriptionType === 'installation') {
                            response = await descriptionService.getInfrastructureDescriptionsPerInfra(elementId, userToken);
                        }

                        if (descriptionType === 'resource') {
                            response = await resourceService.getResourceInfos(elementId, userToken);
                        }

                        setDescriptionsData(response)

                        setIsDescriptionsDataLoading(false);

                        setDescriptionSubmitted(false);

                    } catch (error) {
                        console.error(error);
                        setIsDescriptionsDataLoading(false);
                    }
                }
                handleGetDescriptions().then();
            }

        }, 200)

        return () => {
            clearTimeout(timer);
        };
    // }, [typeId]);
    }, [elementId, descriptionSubmitted]);

    // return { isDescriptionsDataLoading, descriptionsData };
    return { isDescriptionsDataLoading, descriptionsData, setDescriptionSubmitted };
}