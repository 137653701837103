import {Card} from "./Card";
import {Box, Grid, RadioGroup} from "@mui/material";
import {getLimitedData} from "../../../utils/getLimetedData";
import {useFetchAllNetworks} from "../hooks/useFetchAllNetworks";
import {AnimatePresence, motion} from "framer-motion";
import { FiAlertCircle } from "react-icons/fi";
import FormControl from "@mui/material/FormControl";
import {useEffect} from "react";
import {LoadMore} from "../../../components/button/LoadMore";

export const NetworkList = ( { itemsPerRow, setTotalItems, networkId, setNetworkId, setIsShow, setItemsPerPage}) => {
    // Get all networks
    const { data, isLoading, error, totalItems } = useFetchAllNetworks();

    const myData = getLimitedData(data, itemsPerRow);

    useEffect(() => {
        setTotalItems( total => total = totalItems);

        if (!isLoading) {
            setIsShow(true)
        }
    }, []);

    const handleChange = (event) => {
        setNetworkId(event.target.value);
    };

    return (

        <AnimatePresence>
            { !isLoading &&
                <motion.div
                    initial={{opacity: 0, translateY: -50}}
                    animate={{opacity: 1, translateY: 0}}
                    exit={{opacity: 0, translateY: -50}}
                    className="w-full"
                >
                    <FormControl className="w-full flex items-center justify-start">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="network"
                            value={networkId}
                            onChange={handleChange}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: networkId ? 12 : 16 }}>
                                    { myData?.map( (item) =>
                                        <Grid item key={item.id} xs={2} sm={4} md={4} sx={{width: "100%"}} >
                                            <Card data={item} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>

                        </RadioGroup>
                    </FormControl>

                    { totalItems > itemsPerRow &&
                        <LoadMore
                            dataLoad={4}
                            itemsPerPage={itemsPerRow}
                            setItemsPerPage={setItemsPerPage}
                            totalItems={myData.length}
                        />

                    }
                </motion.div>
            }
            { error &&
                    <motion.p
                        className="flex items-center justify-center gap-2 flex-col"
                        initial={{opacity: 0, translateY: -50}}
                        animate={{opacity: 1, translateY: 0}}
                        exit={{opacity: 0, translateY: -50}}

                        transition={{type: 'spring', duration: .5, ease: 'easeOut'}}
                    >
                        <span className='text-2xl text-blue-500'><FiAlertCircle/></span>Something it wrong! Please try again
                    </motion.p>
            }
        </AnimatePresence>

    )
}