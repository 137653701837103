import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import TabsService from "../service/TabsService";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const useFetchOneTab = (id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const user = useSelector(selectUser);
    const token = user.token;

    useEffect(() => {
        return () => {
            fecthAllTab()
        };
    }, [token]);

    async function fecthAllTab () {

        if (!id) return;

        setIsLoading(true);

        try {
            const tabsService =  new TabsService();

            const response = await tabsService.GetTab(id, token);

            setData(response);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return { data, isLoading, error }
}