import {useEffect, useState} from "react";
import InfrastructureService from "../../installation/service/InfrastructureService";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllPlatform} from "../../../redux/platform/platformAction";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);
export const useFetchPlatform = (infraId) => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = useSelector(selectUser);
    const token = user?.token;

    useEffect(() => {
        async function fetchPlatform () {
            try {
                const infrastructureService = new InfrastructureService();
                const res = await infrastructureService.getInfrastructuresFromUser(token);

                dispatch(fetchAllPlatform(res));
                setData(res);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false)
            }
        }

        fetchPlatform();

    }, [infraId, token])

    return { data, loading, error }
}