import AxiosService from "../../../services/AxiosService";

const urlAllServices = "api/service_types";
class NewsService {

    static getAllServices() {
        return AxiosService.getWithoutToken(urlAllServices, "jsonld");
    }
    getServiceById(networkId,id) {
        return AxiosService.getWithoutToken(urlAllServices + "/description/" + networkId + "/" + id, "json");
    }

    getServiceByNetworkId(id, page, itemsPerPage, list) {
        let url = urlAllServices + "/list/network/" + id + `?page=${!list ? '1': page}&itemsPerPage=500`;
        // if(property && property.length > 0){
        //     url = url + property
        // }
        return AxiosService.getWithoutToken(url, "jsonld");
    }

    getServiceByNetworkIdWithFilter(id, page, itemsPerPage, filter, list) {
        return AxiosService.getWithoutToken(urlAllServices + "/list/network/" + id + `?page=${!list ? '1': page}&itemsPerPage=500${ filter ? '&' + filter : "" }`, "jsonld");
    }
    getServicesFilterById(id) {
        return AxiosService.getWithoutToken(urlAllServices + "/description/filters/network/" + id, "jsonld");
    }
}

export default NewsService;