import {FiBox, FiCloud, FiCpu, FiFileText, FiHelpCircle, FiLogOut, FiPocket, FiSettings} from "react-icons/fi";
import {Link, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {useFetchNetworkConfig} from "../../hooks/useFetchNetworkConfig";

export const MenuNetwork = ({ activeMenu, setActiveMenu }) => {
    const {id} = useParams();
    const { data, isLoading, error } = useFetchNetworkConfig();

    const handleActiveMenu = (value) => {
        setActiveMenu(value);
    }
    const navigate = useNavigate();

    const handleClickLeaveNetwork = () => {
        return navigate('/list/networks')
    }

    return (
        <nav className="flex flex-col justify-center gap-10 h-full">
            <div className="h-1/4">
                <ul className="flex flex-col gap-0 w-full">
                    {menuNetwork.map((item) =>
                        <li
                            key={item.id}
                            className={`px-8 py-4 flex items-center font-medium gap-2 cursor-pointer ${activeMenu === item.id ? "bg-slate-100 text-blue-500" : " text-slate-500 bg-transparent"} relative`}
                            onClick={() => handleActiveMenu(item.id)}
                        >
                            <span className={`${activeMenu === item.id ? "text-orange-500" : "text-slate-500"}`}>{item.icon}</span>{item.title}
                        </li>
                    )}
                </ul>
            </div>

            <hr/>

            <div className="w-full grow h-1/4 flex flex-col items-stretch">
                <ul className="flex flex-col gap-4">
                    {menuDown?.map(item => (
                        <li
                            key={item.id}
                            className={`cursor-pointer flex items-center justify-start gap-2 px-8 relative ${activeMenu === null ? "text-orange-500" : "text-slate-500"}`}
                            onClick={() => handleActiveMenu(null)}
                        >
                            <span className={`${activeMenu === null && "text-orange-500 animate-spin"}`}>{item.icon}</span>{item.title}
                        </li>
                    ))
                    }
                </ul>
            </div>
        </nav>
    )
}

const menuNetwork = [
    {
        id: 1,
        title: "Installation",
        icon: <FiCpu/>
    },
    {
        id: 2,
        title: "Submit project",
        icon: <FiFileText/>
    },
    {
        id: 3,
        title: "Services",
        icon: <FiBox/>
    },
    {
        id: 4,
        title: "Custom Select",
        icon: <FiPocket/>
    }
];

const menuDown = [
    // {
    //     id: 1,
    //     title: "Help ?",
    //     icon: <FiHelpCircle />
    // },
    {
        id: 2,
        title: "Settings",
        icon: <FiSettings />
    }
]