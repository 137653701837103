import { useState, useEffect } from 'react';
import InfrastructureService from "../service/InfrastructureService";
import { useSelector } from "react-redux";

export const useFetchAllInstallation = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        async function fetchListInfra() {
            try {
                const installation = new InfrastructureService();
                const res = await installation.getAllInfrastructures(token);

                setData(res["hydra:member"]);
                setTotalItems(res["hydra:totalItems"]);
                setIsLoading(false);

            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchListInfra();

    }, []);

    return { data, totalItems, isLoading, error };
}
