import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useContext, useEffect, useState} from "react";
import {Loader} from "../../../../components/loader/Loader";
import {displayMessage} from "../../../../redux/alertAction";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {FormLoader} from "../../loaders/Loaders";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {useFetchOneServiceType} from "../../hooks/useFetchServiceType";
import ServiceTypeService from "../../services/ServiceTypeService";
import {useNavigate} from "react-router-dom";
import {useFetchUserInfra} from "../../../user/hook/useFetchUserInfra";
import Select from "react-select";
import * as React from "react";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);


export const FormServiceType = ({increment}) => {

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const context = useContext(DrawerTemporaryContext)

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    const role = useFetchUserRole();

    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
            responsable: [],
            visible: false,
        },
    });

    const [isLoading, setIsLoading] = useState(true);

    const {serviceType} = useFetchOneServiceType(context.idSelected, user.token, increment, setIsLoading);

    const {userInfra} = useFetchUserInfra(currentInfrastructure);
    const listResponsable = [...userInfra];


    useEffect(() => {
        if (!isLoading) {
            if (null !== serviceType) {
                setValue('name', serviceType.name ?? "");
                setValue('description', serviceType.description ?? "");
                setValue('responsable', serviceType.responsable.id ?? null);
                setValue('visible', serviceType.isVisible ?? false);
            } else {
                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });
            }
        }
    }, [
        isLoading,
        serviceType
    ]);


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            setSubmitting(true);
            if (user.isSuperAdministrator || ((isCreate && role.canCreateServiceTypes) || (isUpdate && role.canUpdateServiceTypes))) {
                const requestData = {
                    name: data.name,
                    description: data.description,
                    infrastructure: `/api/infrastructures/${currentInfrastructure}`,
                    responsable: `/api/user_infras/${data.responsable}`,
                    isVisible: data.visible
                };

                const serviceTypeService = new ServiceTypeService();
                if (isCreate) {
                    await serviceTypeService.postServiceTypeWithToken(requestData, token);
                } else if (isUpdate) {
                    await serviceTypeService.putServiceTypeWithToken(requestData, context.idSelected, token);
                }

                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });

                dispatch(displayMessage("Success", isCreate ? "Element created" : "Element updated", "success"));

                context.onDrawerClose();
            }
            setSubmitting(false);

        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try {
            if (role && (user.isSuperAdministrator || role.canDeleteServiceTypes)) {
                setSubmitting(true);
                const serviceTypeService = new ServiceTypeService();
                await serviceTypeService.deleteServiceTypeWithToken(context.idSelected, token);


                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });

                dispatch(displayMessage("Success", "Element deleted", "success"));
                setSubmitting(false);
                context.onDrawerClose();
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }

    if (!role
        || (!user.isSuperAdministrator
            && (
                ((!role.canUpdateServiceTypes && !role.canDeleteServiceTypes) && isUpdate)
                || (!role.canCreateServiceTypes && isCreate)
            )
        )) {
        return <Forbidden isInDrawer={true} />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                    {/* 01 */}
                    {isLoading ?
                        (<FormLoader/>)
                        :
                        (
                            <>
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-4 ">
                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide required">Name</h3>
                                        </div>
                                        <div className="w-full">
                                            <input type="text"
                                                   placeholder="Name"
                                                   {...register("name", {required: "Name is required"})}
                                                   className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                            />
                                            {errors.name && !watch("name") && (
                                                <span className={"text-sm text-red-500/70"}
                                                      role="alert">{errors.name.message}</span>
                                            )}
                                        </div>

                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide">Description</h3>
                                        </div>
                                        <div className="w-full">
                                            <input type="text"
                                                   placeholder="Description"
                                                   {...register("description")}
                                                   className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                            />
                                        </div>

                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide required">Responsable</h3>
                                        </div>
                                        <div className="w-full">
                                            <Controller
                                                name="users"
                                                control={control}
                                                render={({field}) => (
                                                    <Select
                                                        {...register("responsable", {required: "Responsable is required"})}
                                                        {...field}
                                                        onChange={(userInfra) => {
                                                            setValue("responsable", userInfra.id);
                                                        }}
                                                        options={listResponsable}
                                                        defaultValue={listResponsable.find((option) => option.id === serviceType?.responsable?.id ?? null)}
                                                        getOptionLabel={(option) => option?.user?.fullName} // Récupère le nom de l'option
                                                        getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                            {errors.responsable && !watch("responsable") && (
                                                <span className={"text-sm text-red-500/70"}
                                                      role="alert">{errors.responsable.message}</span>
                                            )}
                                        </div>

                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide required">Visible in the
                                                service catalog</h3>
                                        </div>
                                        <div className="w-full">
                                            <input type="checkbox"
                                                   {...register("visible")}
                                                   className="border border-slate-300 border-solid rounded-sm grow h-12 p-4 w-4 focus:outline-none focus:ring focus:ring-blue-200"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* 02 save change button */}
                                <div>
                                    <input
                                        type="submit"
                                        value={isCreate ? "Create" : "Update"}
                                        className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                    />
                                    {isUpdate &&
                                        <>
                                            {(user.isSuperAdministrator || role.canDeleteServiceTypes) &&
                                                <>
                                                    <input
                                                        type="button"
                                                        value="Delete"
                                                        onClick={() => {
                                                            setOpenConfirmation(true);
                                                        }}
                                                        className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                    />
                                                    <ConfirmDialog
                                                        isOpen={openConfirmation}
                                                        onClose={() => setOpenConfirmation(false)}
                                                        setIsOpen={setOpenConfirmation}
                                                        onAgree={() => handleDelete()}
                                                    />
                                                </>
                                            }

                                            {(user.isSuperAdministrator || role.canAccessServiceTypes) &&
                                                <input
                                                    type="button"
                                                    value="Full Description"
                                                    onClick={() => {
                                                        navigate('/config/servicetypes/description/' + serviceType.id);
                                                    }}
                                                    className="py-3 ml-2 px-4 bg-green-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </>)
                    }
                    </>
                )}
        </form>
    )
}