import {useContext, useEffect, useState} from "react";
import UserService from "../../../../services/UserService";
import CreatableSelect from "react-select/creatable";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../../../redux/alertAction";
import {isValidEmail} from "../../../ppa/utils/CustomFunctions";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {createSelector} from "reselect";
import {Controller, useForm} from "react-hook-form";
import {useFetchOneUserInfra} from "../../../user/hook/useFetchUserInfra";
import {Loader} from "../../../../components/loader/Loader";
import {FormLoader} from "../../../servicetype/loaders/Loaders";
import Select from "react-select";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import * as React from "react";
import {FiInfo} from "react-icons/fi";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";

const components = {
  DropdownIndicator: null,
};

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

export function UserForm({roles, teams, increment})
{

    // User token
    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const role = useFetchUserRole();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    const isResend = method === "RESEND";
    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [emails, setEmails] = useState([]);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues} = useForm({
        defaultValues: {
            emails: [],
            role:[],
            teams: [],
        },
    });
    const createOption = (label) => ({
        label,
        value: label,
    });

    const [isLoading, setIsLoading] = useState(true);

    const { userInfra } = useFetchOneUserInfra(context.idSelected, user.token, increment, setIsLoading);

    useEffect(() => {
        if (!isLoading) {
            setEmails([]);
            setInputValue('');
            if (null !== userInfra) {
                const currentEmail = userInfra?.user?.email ?? [];
                setEmails((prev) => [...prev, createOption(currentEmail)]);
                setInputValue('');
                setValue('emails', emails);
                setValue('role', userInfra?.role ?? null);
                setValue('teams', userInfra?.listTeams?.filter((t) => !t.isProject) ?? []);
            } else {
                reset({
                    emails: [],
                    role: null,
                    teams: [],
                });
            }
        }
    }, [isLoading, userInfra]);

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        if (event.key === 'Enter' || event.key === 'Tab' || event.key === ' ' || event.key === ',') {
            const newInputValue = inputValue.trim();
            if (typeof inputValue === 'string' && inputValue.length > 0 && isValidEmail(newInputValue)) {
                setEmails((prev) => [...prev, createOption(newInputValue)]);
                setInputValue('');
            }
            event.preventDefault();
        }
    }


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            setSubmitting(true);
            const requestData = {
                emails: data.emails.map((e) => e.value),
                role: `/api/roles/${data.role?.id}`,
                listTeams: data.teams.map((t) => `/api/teams/${t.id}`),
                infrastructure: `/api/infrastructures/${currentInfrastructure}`,
            };
            const userService = new UserService();
            if (isCreate || isResend) {
                userService.inviteUsers(requestData, user.token).then(() => {
                    reset({
                        emails: [],
                        role: null,
                        teams: [],
                    });

                    dispatch(displayMessage("Success", `An email has been successfully sent`, "success"));

                    context.onDrawerClose();
                });
            } else if (isUpdate) {
                userService.update(userInfra.id, requestData, user.token).then(() => {
                    reset({
                        emails: [],
                        role: null,
                        teams: [],
                    });

                    dispatch(displayMessage("Success", `User '${userInfra?.user?.fullName}' has been updated`, "success"));

                    context.onDrawerClose();
                });
            }
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try{
            setSubmitting(true);
            const userService = new UserService();
            userService.delete(userInfra.id, user.token).then(() => {
                reset({
                    emails: [],
                    role: null,
                    teams: [],
                });

                dispatch(displayMessage("Success", `User '${userInfra?.user?.fullName}' has been removed`, "success"));

                context.onDrawerClose();
                setSubmitting(false);
            });

        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }

    if (!user
        || !role
        || (!user.isSuperAdministrator && (
            ((!role.canUpdateUsers && !role.canDeleteUsers) && isUpdate)
            || (!role.canCreateUsers && isCreate)
            || ((!role.canUpdateUsers && !role.canDeleteUsers) && isResend)
        ))
    ) {
        return <Forbidden isInDrawer={true} />
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                        {/* 01 */}
                        {isLoading ?
                            (<FormLoader/>)
                            :
                            (
                                <>
                                    <div className="flex flex-col gap-4">
                                        {(isUpdate || isResend) &&
                                            <h2 className="text-xl font-bold mb-2 tracking-wide">{userInfra?.user?.fullName}</h2>
                                        }
                                        <div className="flex flex-col gap-4 ">
                                            {(isCreate || isResend) &&
                                                <>
                                                    <div className={"flex flex-row"}>
                                                        <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                                        Email(s)
                                                        </h3>
                                                        <FiInfo title={"You can enter many emails, separated by comma. Press enter to validate email"} />
                                                    </div>
                                                    <div className="w-full">
                                                    <Controller
                                                            name="emails"
                                                            control={control}
                                                            render={({field}) => (
                                                                <CreatableSelect
                                                                    {...register("emails", {required: "At least one email is required"})}
                                                                    {...field}
                                                                    components={components}
                                                                    inputValue={inputValue}
                                                                    onBlur={(e) => {
                                                                        setValue("emails", emails);
                                                                        const newValue = e.target.value.trim();
                                                                        if (isValidEmail(newValue)) {
                                                                            setInputValue('');
                                                                        } else {
                                                                            setInputValue(newValue)
                                                                        }
                                                                    }}
                                                                    isClearable
                                                                    isMulti
                                                                    required={false}
                                                                    menuIsOpen={false}
                                                                    onChange={(newValue) => {
                                                                        setEmails(newValue)
                                                                    }}
                                                                    onInputChange={(newValue) => {
                                                                        setInputValue(newValue)
                                                                    }}
                                                                    onKeyDown={handleKeyDown}
                                                                    placeholder="Type user(s) email address(es) to invite"
                                                                    value={emails}
                                                                    name="emails-input"
                                                                />
                                                            )}
                                                        />
                                                        {errors.emails && !watch("emails") && (
                                                            <span className={"text-sm text-red-500/70"}
                                                                  role="alert">{errors.emails.message}</span>
                                                        )}
                                                    </div>
                                                </>
                                            }


                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Role</h3>
                                            </div>
                                            <div className="w-full">
                                                <Controller
                                                    name="role"
                                                    control={control}
                                                    render={({field}) => (
                                                        <Select
                                                            {...register("role", {required: "Role is required"})}
                                                            {...field}
                                                            onChange={(role) => {
                                                                setValue("role", role);
                                                            }}
                                                            options={roles}
                                                            defaultValue={roles?.find((option) => option.id === userInfra?.role?.id ?? null)}
                                                            getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                                            getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                                {errors.role && !watch("role") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.role.message}</span>
                                                )}
                                            </div>

                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Teams</h3>
                                            </div>
                                            <div className="w-full">
                                                <Controller
                                                    name="teams"
                                                    control={control}
                                                    render={({field}) => (
                                                        <Select
                                                            {...register("teams", {required: "Teams is required"})}
                                                            {...field}
                                                            onChange={(t) => {
                                                                setValue("teams", t);
                                                            }}
                                                            isMulti
                                                            options={teams}
                                                            getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                                            getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                                {errors.teams && !watch("teams") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.teams.message}</span>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    {/* 02 save change button */}
                                    <div>
                                        <input
                                            type="submit"
                                            value={isCreate ? "Send an invitation" : "Update"}
                                            className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                        />
                                        {isUpdate &&
                                            <>
                                                {(user.isSuperAdministrator || role.canDeleteUsers) &&
                                                    <>
                                                        <input
                                                            type="button"
                                                            value="Delete"
                                                            onClick={() => {
                                                                setOpenConfirmation(true);
                                                            }}
                                                            className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                        />
                                                        <ConfirmDialog
                                                            isOpen={openConfirmation}
                                                            onClose={() => setOpenConfirmation(false)}
                                                            setIsOpen={setOpenConfirmation}
                                                            onAgree={() => handleDelete()}
                                                            title={`Remove user`}
                                                            content={`Are you sure you want to remove ${userInfra?.user?.fullName} from your installation ?`}
                                                            textBtnConfirm={`Yes, remove '${userInfra?.user?.fullName}' from my installation`}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </>)
                        }
                    </>
                )}
        </form>
    )
}