import {CustomButton} from "../../button/CustomButton";

export const AuthButtons = () => {

    return (
        <>
            <ul className="right-menu flex flex-wrap gap-5 p-4">
                <li className="w-full lg:w-auto ">
                    <CustomButton
                        title="Log In"
                        path="login"
                        color="dark"
                        variant="outlined"
                        size="small"

                        roundedborder
                        width
                    />
                </li>
            </ul>
        </>
    )
}
