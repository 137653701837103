import {useContext, useEffect, useState} from "react";
import {DrawerLoader} from "../../../ppa/components/loaders/Loaders";
import {useFetchTeam} from "../../hooks/api/useFetchTeam";
import TeamService from "../../../../services/TeamService";
import {useFetchInfrastructureUsers} from "../../hooks/api/useFetchInfrastructureUsers";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../../../redux/alertAction";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {Controller, useForm} from "react-hook-form";
import {Loader} from "../../../../components/loader/Loader";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import * as React from "react";
import {createSelector} from "reselect";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

export function TeamForm({increment})
{

    const user = useSelector(selectUser);
    const currentInfrastructureId = useSelector(selectCurrentInfrastructure);
    const context = useContext(DrawerTemporaryContext);
    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    const userInfraRole = useFetchUserRole();
    const isRead = !userInfraRole || (!user.isSuperAdministrator && (!userInfraRole.canUpdateTeams && !userInfraRole.canCreateTeams));

    const defaultFormValues = {
        name: "",
        infrastructure: '/api/infrastructures/' + currentInfrastructureId,
        listUserInfras: []
    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues} = useForm({
        defaultValues: defaultFormValues
    });

    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const {team} = useFetchTeam(context.idSelected, user.token, increment, setLoading);
    const {users} = useFetchInfrastructureUsers(currentInfrastructureId, user.token, increment);
    const [teamId, setTeamId] = useState(0);


    useEffect(() => {
        setTeamId(team?.id ?? 0);
        if (!isLoading) {
            if (null !== team) {
                setValue('name', team.name ?? "");
                setValue('listUserInfras', team.listUserInfras ?? []);
                setIsDisabled(isRead || team.isTeamAll || team.isTeamNotificationNewProject || team.isTeamNotificationGeneralTickets || team.isProject);
            } else {
                reset(defaultFormValues);
                setIsDisabled(false);
            }
        }
    }, [
        isLoading,
        team
    ]);

    const onSubmit = async (data) => {
        try {
            if(!isDisabled) {
                const teamService = new TeamService();
                setSubmitting(true);
                const requestData = {
                    name: data.name,
                    infrastructure: data.infrastructure,
                    listUserInfras: data.listUserInfras.map((userInfra) => userInfra['@id']),
                }

                if (isCreate) {
                    teamService.createTeam(requestData, user.token).then(() => {
                        reset(defaultFormValues);

                        dispatch(displayMessage("Success", `The team '${requestData.name}' has been created`, "success"));

                        context.onDrawerClose();
                    }).catch((e) => {
                        dispatch(displayMessage(e.title, e.detail, 'error'))
                    })
                } else {
                    teamService.updateTeam(teamId, requestData, user.token).then(() => {
                        reset(defaultFormValues);

                        dispatch(displayMessage("Success", `The team '${team?.name}' has been updated`, "success"));

                        context.onDrawerClose();
                    }).catch((e) => dispatch(displayMessage(e.title, e.detail, 'error')))
                }
                setSubmitting(false);
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try {
            if(!isDisabled && !team?.isProject && (user.isSuperAdministrator || userInfraRole?.canDeleteTeams)) {
                const teamService = new TeamService();
                setSubmitting(true);
                teamService.deleteTeam(teamId, user.token).then(() => {
                    reset(defaultFormValues);
                    setSubmitting(false);

                    dispatch(displayMessage("Success", `The team '${team.name}' has been deleted`, "success"));

                    context.onDrawerClose();
                });
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }
    if (isCreate && isRead) {
        return <Forbidden isInDrawer={true}/>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                        {isLoading
                            ? (<DrawerLoader/>)
                            : (
                                <>
                                    {(isDisabled && team) &&
                                        <Alert variant="filled" severity="warning">
                                            The team {team.name} cannot be modified {isRead ? `because you don't have right to do so` : `as it is a ${team.isProject ? 'project' : 'default'} team`}
                                        </Alert>
                                    }
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                                    Team name
                                                </h3>
                                            </div>
                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Role name"
                                                       {...register("name", {
                                                           required: "Team name is required",
                                                           disabled: isDisabled || team?.isProject
                                                       })
                                                       }
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                                {errors.name && !watch("name") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.name.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide">
                                                    Users having team
                                                </h3>
                                            </div>
                                            <div className="w-full">
                                                <fieldset disabled={isDisabled}>
                                                    <Controller
                                                        name="listUserInfras"
                                                        control={control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...register("listUserInfras")}
                                                                {...field}
                                                                onChange={(t) => {
                                                                    setValue("listUserInfras", t);
                                                                }}
                                                                isMulti
                                                                options={users}
                                                                getOptionLabel={(option) => option.user?.fullName.trim() === "" ? option.user?.email : option.user?.fullName} // Récupère le nom de l'option
                                                                getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                                isSearchable
                                                            />
                                                        )}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {!isDisabled && (
                                            <>
                                                {(isCreate || isUpdate) &&
                                                    (
                                                        <input
                                                            type="submit"
                                                            value={isCreate ? "Create" : "Update"}
                                                            className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                        />
                                                    )
                                                }
                                                {(isUpdate && !team?.isProject && (user.isSuperAdministrator || userInfraRole?.canDeleteTeams) ) &&
                                                    <>
                                                        <input
                                                            type="button"
                                                            value="Delete"
                                                            onClick={() => {
                                                                setOpenConfirmation(true);
                                                            }}
                                                            className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                        />
                                                        <ConfirmDialog
                                                            isOpen={openConfirmation}
                                                            onClose={() => setOpenConfirmation(false)}
                                                            setIsOpen={setOpenConfirmation}
                                                            onAgree={() => handleDelete()}
                                                        />
                                                    </>
                                                }
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                    </>
                )}
        </form>
    )
}