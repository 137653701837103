import {AnimatePresence, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import {FiArrowRight, FiArrowUpRight} from "react-icons/fi";
import {useEffect, useState} from "react";
import {toast} from "sonner";
import {useMobile} from "../../hooks/useMobile";
import {isPPARoute} from "../../utils/variables";
import BorderRounded from "../../assets/images/borderrounded.svg";

export const Home = () => {
    const [activeSection, setActiveSection] = useState(2);
    const [dataDisplay, setDataDisplay] = useState(null);

    let navigate = useNavigate();
    const isMobile = useMobile();

    useEffect(() => {

        const sectionFind = dataSection.find( item =>  item.id === activeSection );
        setDataDisplay(sectionFind);

    }, [activeSection]);

    const handleClick = (type, path) => {
        if (type === "ppa") return toast.info("This resource coming soonpage");

        if (type === "catalog") {
            localStorage.removeItem("activeStep");
            localStorage.removeItem("selectedData");
        }

        return navigate(`/${path}`);
    }

    const list = {
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.5,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                when: "afterChildren",
            },
        },
    }

    const handleClickActiveSection = (idSection) => {
        setActiveSection(idSection);
    }

    const handleClickPath = (pathSection) => {
        navigate(`/${pathSection}`);
    }

    return (
        <motion.article
            className="min-h-[80vh] xl:flex justify-center w-full"
            initial={{ translateY: -15, opacity: 0 }}
            transition={{type:"spring", duration:.3, ease:"easeIn" }}
            animate={{ translateY: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className={`container items-stretch min-h-full`}>
                <div className={`${isMobile ? '' : 'wrapper'} h-full flex flex-col lg:pt-10 pt-28`}>
                    <div className={`${!isPPARoute ? 'h-2/5' : 'h-3/5'} flex flex-col gap-2 items-center relative`}>

                        <h2 className={`lg:text-6xl text-3xl lg:leading-[75px] font-bold text-center max-w-[800px] text-slate-900`}>Welcome
                            to the <span className="relative text-blue-500 catalogTitleSpan servicesSpan">
                                ISIA

                            </span> app! Discover our online <span className="relative text-blue-500 catalogTitleSpan servicesSpan"> tools </span>
                        </h2>
                        <motion.button
                            className={`flex items-center justify-center mt-10 gap-4 border rounded-full w-52 h-14 relative px-2 text-slate-500 shadow-sm group`}
                            whileTap={{scale: 1.1}}
                            whileHover={{scale: .988}}

                            transition={{type: "spring", duration: .5, ease: "linear"}}

                            onClick={() => navigate("/about")}
                        >
                            <span
                                className={`group-hover:translate-x-2 transition ease-linear absolute right-4 bg-white shadow-sm flex items-center justify-center border border-slate-200 text-slate-500 font-semibold w-10 h-10 rounded-full p-4`}>
                                <span className="text-lg"><FiArrowRight/></span>
                            </span>
                            <span className="absolute left-10 text-sm">Find out more</span>
                        </motion.button>
                    </div>

                    <div className={`grow relative ${isPPARoute ? "xl:h-3/5 h-screen" : "lg:h-fit py-5"}`}>
                        {!isPPARoute && <div className="linearGradient400"></div>}
                        {isPPARoute ?
                            <motion.ul
                                className="flex items-center xl:flex-row flex-col justify-center w-full h-full gap-2 xl:flex-nowrap overflow-hidden"
                                initial="hidden"
                                animate="visible"
                                variants={list}

                                transition={{type: "spring", stiffness: 100, damping: 50, delay: .2}}
                            >
                                {
                                    dataSection.map((item) =>
                                        <motion.li
                                            key={item.id}
                                            onClick={() => handleClickPath(item.path)}
                                            className={`xl:w-1/2 w-full xl:h-96 h-1/2 flex items-center justify-center relative overflow-hidden group cursor-pointer`}

                                            whileHover={{translateY: -35, rotateY: item.id === 1 ? 5 : -5}}
                                            transition={{type: "spring", duration: .5, ease: "linear"}}
                                        >
                                            <div className="linearGradient400"></div>
                                            <div
                                                className={`xl:max-w-[500px] max-w-96 absolute xl:left-14 transform xl:bottom-10 flex flex-col xl:items-start items-center gap-4 z-10`}>
                                                <motion.p
                                                    className={`text-2xl xl:text-left text-center text-slate-800 font-semibold`}
                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}

                                                    transition={{type: "spring", duration: .2, ease: "linear"}}
                                                >{item.title}</motion.p>
                                                <motion.p
                                                    className={`text-sm font-medium xl:text-left text-center mb-4`}

                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}

                                                    transition={{type: "spring", duration: .2, ease: "linear"}}
                                                >{item.description}</motion.p>
                                                <motion.button
                                                    className={`order-first border border-slate-100 opacity-75 w-10 h-10 text-slate-950 font-medium group-hover:text-slate-50 transition ease-linear flex items-center justify-center rounded-full`}
                                                    onClick={() => {
                                                        handleClickPath(item.path)
                                                        localStorage.removeItem("selectedData");
                                                        localStorage.removeItem("networkCode");
                                                    }}
                                                    title={item.btnText}
                                                    whileHover={{scale: item.isSoon ? 1 : 1.054}}
                                                    whileTap={{scale: item.isSoon ? 1 : .945}}
                                                    transition={{type: "spring", duration: .3, ease: "linear"}}

                                                ><FiArrowUpRight/></motion.button>
                                            </div>

                                            <div
                                                className={`w-full lg:h-96 h-full overflow-hidden`}>
                                                <motion.figure
                                                    className="w-full lg:h-96 h-full"
                                                >
                                                    <img
                                                        src={`/uploads/${item.image}.webp`}
                                                        alt={`fond de couverture ${item.title}`}
                                                        className={`w-full h-full object-cover brightness-175`}
                                                    />
                                                </motion.figure>
                                            </div>
                                        </motion.li>
                                    )
                                }
                            </motion.ul>
                            :
                            <AnimatePresence>
                                {dataDisplay &&
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                        transition={{type: "spring", duration: .2, ease: "linear", delay: .4}}
                                    >
                                        <div
                                            className={`lg:max-w-[550px] max-w-[390px] w-full absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 flex flex-col items-center gap-4 z-10 min-h-52`}>
                                            <motion.p
                                                className={`text-2xl text-center text-slate-800 font-semibold`}
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}}

                                                transition={{type: "spring", duration: .2, ease: "linear"}}
                                            >{dataDisplay.title}</motion.p>
                                            <motion.p
                                                className={`text-md font-medium text-center mb-4`}

                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}}

                                                transition={{type: "spring", duration: .2, ease: "linear"}}
                                            >{dataDisplay.description}</motion.p>
                                            <motion.button
                                                className={`border border-slate-200 bg-white text-slate-950 font-medium fontfamilly w-40 h-14 rounded-full ${dataDisplay.isSoon ? "cursor-not-allowed opacity-40" : ""}`}
                                                onClick={() => handleClickPath(dataDisplay.path)}
                                                title={dataDisplay.btnText}
                                                whileHover={{scale: dataDisplay.isSoon ? 1 : 1.054}}
                                                whileTap={{scale: dataDisplay.isSoon ? 1 : .945}}
                                                transition={{type: "spring", duration: .3, ease: "linear"}}

                                            >{dataDisplay.btnText}</motion.button>
                                        </div>

                                        <div className="overflow-hidden xl:h-[550px] md:h-[450px] h-screen w-full">
                                            <motion.figure
                                                className="xl:h-[550px] md:h-[450px] h-screen w-full"
                                            >
                                                <img
                                                    src={`/uploads/${dataDisplay.image}.webp`}
                                                    alt={`fond de couverture catalog`}
                                                    className="w-full h-full object-cover brightness-100 md:rounded-b-[100px] "
                                                />
                                            </motion.figure>
                                        </div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        }
                    </div>


                    {/*<Partner />*/}

                </div>
            </div>
        </motion.article>
    )
}

const dataSection = [
    {
        id: 1,
        title: "Submit Your Proposal Projects",
        btnText: "Submit",
        description: "Submit your innovative project proposals and contribute to advancing scientific knowledge. Share your ideas and collaborate with a community of forward-thinking researchers. ",
        image: "ppabg",
        path: "ppa",
        type: "ppa",
        isSoon:isPPARoute
    },
    {
        id: 2,
        title: "Browse Our Scientific Catalogs",
        btnText: "Discover",
        description: "Explore a vast collection of catalogs, theses, and cutting-edge research. Easily access valuable information and stay at the forefront of scientific innovation",
        image: "catalogbg1",
        path: "catalog",
        type: "catalog",
        isSoon:false
    },
]
