import * as React from "react";
import {createContext, useState} from "react";

// Redux
import {useDispatch} from "react-redux";
import {displayMessage} from "../../../../redux/alertAction";

// Design
import {motion} from "framer-motion";
import {Box} from "@mui/material";

// Components
import ReusableFinalFormContainer from "../../../../components/reusableform/page/ReusableFinalFormContainer";
import {ResourceInfosFormStructure} from "./ResourceInfosFormStructure";
import ScrollTop from "../../../../components/ScrollTop";
import {FormSuccessContent} from "../../../../components/reusableform/components/contents/items/FormSuccessContent";

// Services
import ResourceService from '../../services/Resource'

// Hooks
import {useFetchFormsDescriptionJoinedInputs} from "../../../description/hooks/useFetchDescriptionForms";

// Custom Functions
import { useLayoutWithInstallationContext } from '../../../../hooks/useLayoutWithInstallationContext'
import { dataToArray } from '../../../../utils/dataToArray'

// Context

export const ResourceInfosFormContext = createContext(null)



export const ResourceInfosFormContainer = ({ resourceInfos, installation, setDescriptionSubmitted, isRead }) => {

    // Working One
    const listTabs = dataToArray(resourceInfos.listTabs);

    // Specific Forms Data Management
    // From Context
    const { user, currentInfrastructure } = useLayoutWithInstallationContext();

    const resourceId = resourceInfos.resourceId ?? 0;

    // Initial State
    const [state, setState] = useState({
        isLoading: false,
        schema: {},
        project: {
            isSent: false,
            isValid: false,
            message: null,
            infrastructure: `/api/infrastructures/${currentInfrastructure}`,
            resource: `/api/resources/${resourceId}`,
            listInputValues: [],
            descriptionForm: true,
        },
    });

    /* To have consistent input indexes */
    const { joinedInputs,
            setJoinedInputs,
            joinedForms,
            isLoadingJoinedContent,
            selectOptions,
            descriptionRetrieveData,
            setDescriptionRetrieveData,
    } = useFetchFormsDescriptionJoinedInputs(listTabs, setState);

    // console.dir(joinedForms)
    // console.dir(joinedInputs)


    // Show Success Confirmation Page
    const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);


    /***** Save/Submit *****/
    const dispatch = useDispatch();
    const resourceService = new ResourceService();

    /***** Sending Edit Installation Form Request *****/
    const sendingFormRequest = async (values) => {

        // TODO Faire le sending form
        // console.log("RETURN; Submitted;", values)
        // return;

        let putDescriptionMethod, parametersContent;

        putDescriptionMethod = 'putResourceInfos';
        parametersContent = [resourceId, values, user?.token];

        const sendMethod = resourceService[putDescriptionMethod];

        await sendMethod(...parametersContent).then(data => {

            if (data.status === undefined || data.status === 201) {

                dispatch(displayMessage("Success","The resource has been updated","success"));

                setState(prevState => ({
                    ...prevState,
                    project: {
                        ...prevState.project,
                        isSent: true,
                        isValid: true,
                        token: data.token,
                    },
                    isLoading: false,
                }));

                // setShowSubmitSuccess(true);
                setDescriptionSubmitted(true);

            } else {
                // Case error
                setState(prevState => ({
                    ...prevState,
                    project: {
                        isSent: true,
                        isValid: false,
                        message: data.data.detail,
                        listInputValues: [values.listInputValues],
                        infrastructureId: currentInfrastructure,
                    },
                    isLoading: false,
                }));
                // console.log(data)
            }
        });
    }

    return (
        <motion.article
            id="reusableform"
            className={`flex w-full h-full resource-infos`}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, delay: .3}}
        >
            <div className="container">

                <div className="wrapper flex flex-col lg:max-w-[996px] ml-auto mr-8 2xl:mx-auto">
                    <Box component={"div"} sx={{width: '100%', height: '100%'}}>

                        <ResourceInfosFormContext.Provider value={{
                            // FormSpecific
                            listTabs,
                            joinedForms,
                            joinedInputs,
                            selectOptions,

                            // List Type Form
                            setJoinedInputs,
                            setState,

                            // Loadings
                            isLoadingJoinedContent,

                            // LTF Retrieve Description Data Status
                            descriptionRetrieveData,
                            setDescriptionRetrieveData
                        }}>

                            {!showSubmitSuccess ? (
                                <ReusableFinalFormContainer
                                    // Fetching Datas
                                    // networkIdOrigin={networkIdOrigin}
                                    // listInfrastructures={listInfrastructures}

                                    // Form
                                    mainState={state.project}
                                    isLoadingState={state.isLoading}
                                    // validate={validate}

                                    // Submit Form
                                    sendingFormRequest={sendingFormRequest}

                                    // FormSpecific
                                    listTabs={listTabs}
                                    joinedInputs={joinedInputs}

                                    // List Type Form
                                    setState={setState}

                                    // FormContentButtons
                                    validMandatory

                                    // Feature Content
                                    featureEdition={
                                        <ResourceInfosFormStructure isRead={isRead} />
                                    }

                                    isRead={isRead}

                                    hideCaptcha={true}
                                />
                            ) : (
                                <Box className="h-full lg:pt-32">
                                    <FormSuccessContent />
                                </Box>
                            )}

                        </ResourceInfosFormContext.Provider>

                    </Box>
                    <ScrollTop/>

                </div>
            </div>
        </motion.article>
    );
}
