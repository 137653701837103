import {NavLink} from "react-router-dom";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";


export const NavBar = ({data}) => {

    const activeClassName = "active";

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );


    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const infrastructure = useSelector(selectCurrentInfrastructure);
    const user = useSelector(selectUser);

    return (
        <nav>
            <ul className="left-menu flex gap-5">
                { pages.filter(page => page.needAuth === true && (user === null || infrastructure === null || infrastructure === 0) ? null : page).map((item) =>
                    <li key={item.id} className="nav-item">
                        <NavLink
                            to={`/${item.link}`}
                            className={`font-medium ${({isActive}) => isActive ? activeClassName : ""}`}
                        >
                            { item.nameLink }
                        </NavLink>
                    </li>
                )}
            </ul>
        </nav>

    )
}


const pages = [
    {
        id: 2,
        nameLink: 'Overview',
        link: '',
        needAuth: false
    },
    {
        id: 3,
        nameLink: 'About',
        link: 'about',
        needAuth: false
    },
    {
        id: 4,
        nameLink: 'News',
        link: 'news',
        needAuth: false
    },
    {
        id: 5,
        nameLink: 'Docs',
        link: 'docs',
        needAuth: false
    }
]