import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useContext, useEffect, useState} from "react";
import {TiTick} from "react-icons/ti";
import {Loader} from "../../../../components/loader/Loader";
import ResourceTypeService from "../../services/ResourceType";
import {displayMessage} from "../../../../redux/alertAction";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {useFetchOneResourceType} from "../../hooks/useFetchResourceType";
import {FormLoader} from "../../loaders/Loaders";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";
import * as React from "react";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);


export const FormResourceType = ({increment}) => {

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const context = useContext(DrawerTemporaryContext)

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const dispatch = useDispatch();

    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";

    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const role = useFetchUserRole();

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            name: "",
            color: "#2d7ef5",
        },
    })

    const [isLoading, setIsLoading] = useState(true);

    const {resourceType, error} = useFetchOneResourceType(context.idSelected, user.token, increment, setIsLoading);

    useEffect(() => {
        if (!isLoading) {
            if (null !== resourceType) {
                setValue('name', resourceType.name ?? "");
                setValue('color', resourceType.color ?? "");
            } else {
                reset();
            }
        }
    }, [
        isLoading,
        resourceType
    ]);


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            if (role) {
                setSubmitting(true);
                if (user.isSuperAdministrator || ((isCreate && role.canCreateResourceTypes) || (isUpdate && role.canUpdateResourceTypes))) {
                    const requestData = {
                        name: data.name,
                        color: data.color,
                        isLocked: false,
                        infrastructure: `/api/infrastructures/${currentInfrastructure}`
                    };

                    const resourceTypeService = new ResourceTypeService();
                    if (isCreate) {
                        await resourceTypeService.postResourceTypeWithToken(requestData, token);
                    } else if (isUpdate) {
                        await resourceTypeService.putResourceTypeWithToken(requestData, context.idSelected, token);
                    }

                    reset({
                        name: "",
                        color: "",
                    });

                    dispatch(displayMessage("Success", isCreate ? "Element created" : "Element updated", "success"));

                    context.onDrawerClose();
                }
                setSubmitting(false);
            }

        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try {
            if (role && (user.isSuperAdministrator || role.canDeleteResourceTypes)) {
                setSubmitting(true);
                const resourceTypeService = new ResourceTypeService();
                await resourceTypeService.deleteResourceTypeWithToken(context.idSelected, token);


                reset({
                    name: "",
                    color: "",
                });

                dispatch(displayMessage("Success", "Element deleted", "success"));
                setSubmitting(false);
                context.onDrawerClose();
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }

    const colors = [
        {
            id: 1,
            value: "#2d7ef5",
            classCss: "bg-[#2d7ef5]"
        },
        {
            id: 2,
            value: "#02b991",
            classCss: "bg-[#02b991]"
        },
        {
            id: 3,
            value: "#272727",
            classCss: "bg-[#272727]"
        },
        {
            id: 4,
            value: "#e1a64f",
            classCss: "bg-[#e1a64f]"
        },
        {
            id: 5,
            value: "#9d61f8",
            classCss: "bg-[#9d61f8]"
        },
        {
            id: 6,
            value: "#ef6d6d",
            classCss: "bg-[#ef6d6d]"
        }
    ];

    if (!role
        || (!user.isSuperAdministrator
            && (
                ((!role.canUpdateResourceTypes && !role.canDeleteResourceTypes) && isUpdate)
                || (!role.canCreateResourceTypes && isCreate)))
    ) {
        return <Forbidden isInDrawer={true} />
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                        {/* 01 */}
                        {isLoading ?
                            (<FormLoader/>)
                            :
                            (
                                <>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4 ">
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Name</h3>
                                            </div>
                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Name"
                                                       {...register("name", {required: "Name is required"})}
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                                {errors.name && !watch("name") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.name.message}</span>
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Color</h3>
                                            </div>
                                            <div className="flex flex-col items-start gap-4">
                                                <ul className={"flex gap-2"}>
                                                    {colors.map((item, key) => (
                                                        <li key={key}>
                                                            <label
                                                                className={`${item.classCss} block w-10 h-10 content-center align-center z-2 cursor-pointer`}>
                                                                {item.value === watch("color") ? <TiTick
                                                                    className='fill-white ml-2.5 h-5 w-5'/> : ""}
                                                                <input type="radio"
                                                                       placeholder="Color"
                                                                       value={item.value}
                                                                       {...register("color", {required: "Color is required"})}
                                                                       className="sr-only"
                                                                />
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                                {errors.color && !watch("color") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.color.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* 02 save change button */}
                                    <div>
                                        <input
                                            type="submit"
                                            value={isCreate ? "Create" : "Update"}
                                            className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                        />
                                        {isUpdate && (user.isSuperAdministrator || role.canDeleteResourceTypes) &&
                                            <>
                                                <input
                                                    type="button"
                                                    value="Delete"
                                                    onClick={() => {
                                                        setOpenConfirmation(true);
                                                    }}
                                                    className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                                />
                                                <ConfirmDialog
                                                    isOpen={openConfirmation}
                                                    onClose={() => setOpenConfirmation(false)}
                                                    setIsOpen={setOpenConfirmation}
                                                    onAgree={() => handleDelete()}
                                                />
                                            </>
                                        }
                                    </div>
                                </>)
                        }
                    </>
                )}
        </form>
    )
}