import {Profil} from "../../../../components/users/Profil";
import {AnimatePresence, motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import {FaNetworkWired} from "react-icons/fa";
import {
    FiArchive,
    FiBell,
    FiFolder, FiGrid,
    FiHome,
    FiSend,
    FiShield,
} from "react-icons/fi";
import React, {useEffect, useRef, useState} from "react";
import {MdOutlineDashboard} from "react-icons/md";
import {SearchModal} from "./SearchModal";

// Custom Hooks
import {useCloseOnOutsideClick} from "../../../../hooks/useCloseOnOutsideClick";
import {useFetchActivities} from "../../../dashboardUser/hooks/useFetchActivities";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFetchNetworkConfig} from "../../../network/hooks/useFetchNetworkConfig";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);


export const RightMenu = ({ userId, token, currentPlatform }) => {
    const user = useSelector(selectUser);
    const currentInfrastructureId = useSelector(selectCurrentInfrastructure);

    const { totalItem: totalItemNetworks } = useFetchNetworkConfig();

    const [inBox, setInBox] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [activeNotif, setActiveNotif] = useState(1);

    const [newNotif, setNewNotif] = useState([]);
    const [archiveNotif, setArchiveNotif] = useState([]);

    const navigate = useNavigate();

    const {data: listNoticiations} = useFetchActivities();

    useEffect(() => {
        const filterNotifNew = listNoticiations.filter( item => item.status === "new");
        const filterNotifRead = listNoticiations.filter( item => item.status === "read");

        setNewNotif(filterNotifNew);
        setArchiveNotif(filterNotifRead);

    }, [listNoticiations]);


    const handleOpenInBox = () => {
        // setInBox(inB => inB = !inBox);
        setInBox(!inBox);
    }

    const handleClickLinkInBox = (link) => {
        return navigate(`${link}`);
    }

    // Notifications Menu
    const handleClickNotif = () => {
        setOpenNotif(!openNotif);
    }

    const notifMenuRef = useRef(null);
    // Outside Element Click Detection
    useCloseOnOutsideClick(notifMenuRef, setOpenNotif)

    const handleClickActiveMenuNotif = (idNotif) => {
        setActiveNotif(idNotif);
    }

    const inBoxData = [
        {
            id: 1,
            title: "Home",
            link: "/",
            icon: <FiHome />,
            bgfrom: "from-purple-700",
            border: "border-gray-500",
            display: true,
        },
        {
            id: 2,
            title: "Dashboard",
            link: "/dashboard",
            icon: <MdOutlineDashboard />,
            bgfrom: "bg-indigo-500",
            border: "border-indigo-500",
            display: currentInfrastructureId > 0,
        },
        {
            id: 3,
            title: "Ppa",
            link: "/ppa",
            icon: <FiSend/>,
            bgfrom: "from-orange-500",
            border: "border-orange-500",
            display: true,
        },
        {
            id: 4,
            title: "Catalog",
            link: "catalog",
            icon: <FiFolder/>,
            bgfrom: "from-yellow-500",
            border: "border-yellow-500",
            display: true,
        },
        {
            id: 5,
            title: "Networks",
            link: "list/networks",
            icon: <FaNetworkWired/>,
            bgfrom: "bg-blue-500",
            border: "border-blue-500",
            display: totalItemNetworks > 0,
        },
        {
            id: 6,
            title: "SuperAdmin",
            link: "#",
            icon: <FiShield />,
            bgfrom: "from-red-500",
            border: "border-red-500",
            display: user.isSuperAdministrator,
        },
    ];

    return (
        <div className="flex items-center justify-end gap-5 min-w-[400px]">
            {/* Choose infra */}
            <SearchModal />
            <ul className="proPlat flex items-center justify-center gap-5 relative">
                <li className="relative">
                    <motion.button
                        className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full"
                        onClick={ handleOpenInBox }
                        onBlur={ handleOpenInBox }
                        title="fast access app"
                        whileTap={{scale: 0.97}}
                        whileHover={{scale: 1.04}}
                    >
                        <span className="w-full h-full flex justify-center items-center text-slate-400 text-md"><FiGrid /></span>
                    </motion.button>

                    <AnimatePresence>
                        { inBox &&
                            <motion.div
                                className="bg-slate-100 w-52 drop-shadow-md top-[70px] absolute right-0 flex gap-4 flex-col items-center justify-center rounded-2xl p-2"
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 10}}
                                transition={{type: "spring", stiffness: 300, damping: 24, delay: .2}}
                            >
                                <div className="rounded-2xl bg-white flex flex-col gap-4 shadow-sm">
                                    <ul className="grid grid-cols-2 h-full w-full text-left gap-4 p-4">
                                        {inBoxData.map(item =>
                                            <React.Fragment key={item.id}>
                                                {item.display &&
                                                    <li className="flex flex-col items-center justify-center p-2">
                                                        <motion.button
                                                            type="button"
                                                            onClick={() => handleClickLinkInBox(item.link)}
                                                            title={item.title}
                                                            className={`w-14 h-14 rounded-full flex items-center text-slate-50 text-2xl font-bold gap-2 justify-center p-2 transition bg-gradient-to-br ${item.bgfrom}`}

                                                            whileTap={{scale: 0.97}}
                                                            whileHover={{scale: 1.04}}
                                                        > {item.icon}
                                                        </motion.button>
                                                        <p className={`text-sm text-slate-500 font-medium`}>{item.title}</p>
                                                    </li>
                                                }
                                            </React.Fragment>
                                        )}
                                    </ul>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>

                </li>

                <li className="relative">
                    <motion.button
                        className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full relative"
                        onClick={ handleClickNotif }
                        whileTap={{scale: 0.97}}
                        whileHover={{scale: 1.04}}
                    >
                    <span className={`w-full h-full flex justify-center items-center text-md text-slate-400 `}><FiBell/></span>
                        {newNotif.length > 0 &&
                            <span className="flex h-2 w-2 absolute top-0 right-0">
                              <span className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-red-500`}></span>
                              <span className={`relative inline-flex rounded-full h-2 w-2 bg-red-400`}></span>
                            </span>
                        }
                    </motion.button>

                    <AnimatePresence>
                        {openNotif &&
                            <motion.div
                                className="bg-white min-w-72 drop-shadow-md top-[70px] absolute right-0 h-96 flex flex-col rounded-sm"
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: 10}}
                                transition={{type: "spring", stiffness: 300, damping: 24, delay: .2}}
                                ref={notifMenuRef}
                            >
                                <div
                                    className="bg-black border border-slate-500 h-14 flex items-center justify-center text-lg font-semibold px-10 w-full">
                                    <p className="text-slate-50 text-sm text-center">Notification <span>({activeNotif === 1 ? newNotif.length : archiveNotif.length})</span></p>
                                </div>
                                <div className={`grow overflow-y-auto overflow-x-hidden ${ newNotif.length === 0 && "flex items-center justify-center" }`}>

                                    {activeNotif === 1 &&
                                        (newNotif.length === 0 ?
                                                <p className="text-slate-500 text-center text-sm flex items-center justify-center flex-col">
                                            <span
                                                className="text-md text-slate-400 border border-slate-200 rounded-full flex items-center justify-center w-10 h-10"><FiArchive/></span>
                                                    No new notifications
                                                </p>
                                                :
                                                <ul className="flex flex-col items-center justify-start h-full w-full text-left gap-0 divide-y divide-slate-200">
                                                    {newNotif.map(item =>
                                                        <li key={item.id}
                                                            className={`relative transition ease-linear hover:bg-slate-50 px-4 py-2 cursor-pointer w-full`}>
                                                            <p className="text-slate-700 leading-5 text-sm">{item.content}</p>
                                                            <span className="text-xs text-slate-500 font-medium">{item.date}</span>
                                                        </li>
                                                    )}
                                                </ul>
                                        )

                                    }
                                    { activeNotif === 2 &&
                                        (archiveNotif.length === 0 ?
                                                <p className="text-slate-500 text-center text-sm flex items-center justify-center flex-col">
                                                <span
                                                    className="text-md text-slate-400 border border-slate-200 rounded-full flex items-center justify-center w-10 h-10"><FiArchive/></span>
                                                    No notifications archived
                                                </p>
                                                :
                                                <ul className="flex flex-col items-center justify-start h-full w-full text-left gap-0 divide-y divide-slate-200">
                                                    {archiveNotif.map(item =>
                                                        <li key={item.id}
                                                            className={`relative transition ease-linear hover:bg-slate-50 px-4 py-2 cursor-pointer w-full ${item.status === "new" ? "bg-zinc-100" : "bg-white"}`}>
                                                            <p className="text-slate-700 leading-5 text-sm">{item.content}</p>
                                                            <span className="text-xs text-slate-500 font-medium">{item.date}</span>
                                                        </li>
                                                    )}
                                                </ul>
                                        )
                                    }
                                </div>
                                <div className="h-8">
                                    <ul className="flex items-center justify-center h-full">
                                        { notifMenu.map(item =>
                                            <li
                                                key={item.id}
                                                className={`text-sm w-1/2 text-center h-full flex items-center justify-center relative cursor-pointer ${activeNotif === item.id ? "bg-white text-blue-500 font-semibold" : "text-slate-500 font-medium bg-slate-100"}`}
                                                onClick={ () => handleClickActiveMenuNotif(item.id)}
                                            >
                                                {item.title}
                                            </li>
                                        )}
                                    </ul>
                                </div>

                            </motion.div>
                        }
                    </AnimatePresence>

                </li>

                <li>
                    <Profil />
                </li>
            </ul>
        </div>
    )
}

const notifMenu = [
    {
        id: 1,
        title: "Inbox",
    },
    {
        id: 2,
        title: "Archive",
    },
]