import {useDispatch, useSelector} from "react-redux";
import {
    Autocomplete, Box,
    FormControl, TextField,
} from "@mui/material";
import {changeInfrastructure} from "../../../redux/user/changeInfrastructureAction";
import { useNavigate} from "react-router-dom";
import {createSelector} from "reselect";

import {styled, lighten, darken} from '@mui/system';
import {useFetchPlatform} from "../hook/useFetchPlatform";

import {useInfrastructuresSelect} from "../../../hooks/useInfrastructuresSelect";
import { motion} from "framer-motion";


import {FiSettings} from "react-icons/fi";
import {useState} from "react";
import {reset} from "../../../redux/platform/platformAction";
import {useFetchUserRole} from "../../../hooks/useFetchUserRole";

// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_API_URL;

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

const GroupHeader = styled('div')(({theme}) => ({
    position: 'sticky',
    top: '-8px',
    padding: '10px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export default function PlatformList() {
    const [openInfoInstall, setOpenInfoInstall] = useState(false);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const user = useSelector(selectUser);
    const token = user?.token;
    const role = useFetchUserRole();

    const {data } = useFetchPlatform();
    useInfrastructuresSelect(currentInfrastructure, token);
    // const data = useSelector((state) => state.platformReducers.data);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangePlatform = async (e, platform) => {
        const getId = new Promise((resolve) => {

            let id = null !== platform ? platform.id : 0;

            if (null !== platform && null !== platform.parent && false === platform.parent.isGroupOnly) {
                id = platform.parent.id;
            }
            resolve(id);
        });

        await getId.then(id => {
            dispatch(changeInfrastructure(id));
            if (id > 0) {
                navigate("/dashboard");
            } else {
                dispatch(reset());
                navigate("/")
            }
        });
    }

    let filterWithoutParent = data?.filter( option => option.parent !== null );
    filterWithoutParent = filterWithoutParent?.sort((a, b) => -b?.parent?.name.localeCompare(a?.parent?.name));

    const handleEditInstalltion = () => {
        return navigate("/installation/description");
    }

    // Button installation
    const handleOpenInfoInstall = () => {
        setOpenInfoInstall(true);
    }

    const handleCloseInfoInstall = () => {
        setOpenInfoInstall(false);
    }
    return (
        <div className="flex items-center gap-2 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
            <div className="min-w-96 border border-solid border-slate-200 p-1 h-14 rounded-full drop-shadow-sm">
                <FormControl variant="standard" sx={{width: "100%"}}>
                    <ul className="w-full">
                        <Autocomplete
                            size="small"
                            groupBy={(option) => option.parent ? option.parent.name : "No parent"}
                            renderGroup={(params) => (
                                <li key={params.key} className="flex flex-col">
                                    <GroupHeader>{params.group}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )}
                            getOptionLabel={(option) => `${option.name}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label=""
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                    placeholder="Select an installation"
                                    sx={{
                                        fieldset: {
                                            border: 'none',
                                        },

                                        background: "transparent !important"
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <ul key={`ul-${option.id}`}>
                                    <Box
                                        {...props}
                                        component="li"
                                        key={`box-${option.id}`}
                                        sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                    >
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={url + option.logo}
                                            alt=""
                                        />
                                        {option.name}
                                    </Box>
                                </ul>
                            )}
                            options={filterWithoutParent}
                            onChange={handleChangePlatform}
                            value={data.find((option) => option.id === currentInfrastructure) ?? null}
                            sx={{
                                fontSize: "1.75rem",
                                fontWeight: "500",
                                textAlign: "center",
                                ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                                    color: "rgb(148 163 184)",
                                    background: "none"
                                },
                                "@media (max-width: 1024px)": {
                                    ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                                        backgroundColor: "rgb(241 245 249)",
                                        color: "rgb(148 163 184)",
                                        height: "55px"
                                    },
                                    ".MuiAutocomplete-input": {
                                        textAlign: "center"
                                    }
                                }
                            }}
                        ></Autocomplete>
                    </ul>
                </FormControl>
            </div>

            {(currentInfrastructure !== null && currentInfrastructure > 0 && role && (role.isRoleAdmin || user.isSuperAdministrator)) && (

                <div className="relative">
                    <motion.button
                        type="button"
                        onClick={handleEditInstalltion}
                        onMouseEnter={ handleOpenInfoInstall }
                        onMouseLeave={ handleCloseInfoInstall }
                        className={` text-xs text-slate-700 border border-slate-400 rounded-full w-8 h-8 flex items-center justify-center`}
                        title={"Click here to edit your installation"}
                        whileHover={{scale: 1.045}}
                        whileTap={{scale: .945}}
                    >
                        <FiSettings />
                    </motion.button>

                    {/*{openInfoInstall &&*/}
                    {/*    <motion.div*/}
                    {/*        className={`bg-white rounded-lg border border-slate-200 h-16 w-40 absolute left-0 -bottom-20 flex items-center justify-center`}*/}
                    {/*        initial={{ opacity:0, translateY:-25 }}*/}
                    {/*        animate={{ opacity:1, translateY:0 }}*/}
                    {/*        exit={{ opacity:0, translateY:25 }}*/}

                    {/*        transition={{ type:"spring", duration: .6, ease: "linear" }}*/}
                    {/*    >*/}
                    {/*        <div className="relative h-full w-full p-2">*/}
                    {/*            <p className="text-sm text-slate-500 ">*/}
                    {/*                Click here to edit your installation description*/}
                    {/*            </p>*/}
                    {/*            <span className="border border-slate-200 bg-white flex items-center justify-center w-6 h-6 text-sm text-slate-500 rounded-full absolute -top-2 -right-2"><FiInfo /></span>*/}
                    {/*        </div>*/}
                    {/*    </motion.div>*/}
                    {/*}*/}
                </div>

            )}

        </div>
    );
}