import AxiosService from "../../../services/AxiosService";

const urlEcosystems = 'api/ecosystems?page=1&infrastructures.id=';
const urlClimateZones = 'api/climate_zones?page=1&infrastructures.id=';
const urlInfrastructuresByNetwork = 'api/infrastructures/list/light';

const urlInstallation = 'api/infrastructures/network/'


class InfrastructureService {

    getInfrastructure(id, token) {
        return AxiosService.getWithToken('api/infrastructures/' + id, token, 'jsonld')
    }

    getInfrastructureById(idcatalog, id) {
        return AxiosService.getWithoutToken('api/infrastructures/description/'+ id + '/' + idcatalog, 'jsonld');
    }

    getInfrastructureByURI(uri) {
        return AxiosService.getWithoutToken(uri, 'jsonld');
    }

    getInfrastructureByNetwork(id) {
        return AxiosService.getWithoutToken(urlInfrastructuresByNetwork + '/' + id, 'jsonld');
    }

    getInfrastructureNetworkById(id, page, itemsPerPage, list) {
        return AxiosService.getWithoutToken(urlInstallation + id + `?page=${list ? page : '1'}&itemsPerPage=500`, 'jsonld')
    }
    getInstallationByNetworkIdWithFilter(id, page, itemsPerPage, filter, list) {
        return AxiosService.getWithoutToken(urlInstallation + id + `?page=${!list ? '1': page}&itemsPerPage=500&${ filter ? filter : "" }`, "jsonld");
    }
    getAllInfrastructures(token) {
        return AxiosService.getWithToken('api/infrastructures',token,  'jsonld');
    }

    getAllInfrastructuresNetworkConfig(token) {
        return AxiosService.getWithToken('api/infrastructures/all/light', token, 'jsonld');
    }

    getInfrastructureEcosystems(id) {
        return AxiosService.getWithoutToken(urlEcosystems + id )
    }

    getInfrastructureClimateZones(id) {
        return AxiosService.getWithoutToken(urlClimateZones + id )
    }

    getInfrastructuresFromUser(token) {
        return AxiosService.getWithToken('api/infrastructures', token);
    }

    putInfrastructuresSelect(idInfra, token) {
        return AxiosService.putWithToken('api/infrastructures/select/' + idInfra, [], token, 'jsonld')
    }

}

export default InfrastructureService;