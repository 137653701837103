import {useEffect} from "react";

export const useCloseOnOutsideClick = (refElement, setOpenElement) => {
    useEffect(() => {
        const handleCloseOnClickOutside = (event) => {
            (refElement.current && !refElement.current.contains(event.target)) && setOpenElement(false);
        }
        // Bind the EventListener
        document.addEventListener("mousedown", handleCloseOnClickOutside);
        return () => {
            // Unbind the EventListener on clean up
            document.removeEventListener("mousedown", handleCloseOnClickOutside);
        };
    }, [refElement]);
}