import * as React from "react";
import {useEffect, useState} from "react";

// Design
import {Backdrop, Box, IconButton} from "@mui/material";

// Components
import {NavigationTabsContainer} from "./NavigationTabsContainer";
import {useMobile} from "../../../hooks/useMobile";
import CloseIcon from "@mui/icons-material/Close";
import {FiAlignJustify} from "react-icons/fi";


const useOnWindowScroll = (callback) => {
    useEffect(() => {

        window.addEventListener('scroll', callback);

        return () => {
            window.removeEventListener('scroll', callback);
        };
    }, [callback]);
};

const NavigationContent = ({ props, headerElementHeight, fixedValue, handleClose, isMobile  }) => {

    // Mui Tabs
    const {
        featureStandardDescriptions, standardTabValue, handleChangeStandard,
        featureAdditionalDescriptions, additionalTabValue, handleChangeAdditional,
        descriptionTitle, descriptionSubTitle
    } = props;


    return (
        <Box component={"div"}
             className={`fixed bg-transparent py-6 w-full flex justify-center lg:justify-start lg:w-1/5 lg:ml-4 lg:mr-8 z-10 `}
             sx={{
                 top: `${headerElementHeight}px`,
                 transition: 'all .2s ease-in-out',
                 ...!fixedValue && ({
                     top: '0',
                 })
             }}
        >
            <div className={`bg-gradient-to-t from-white to-stone-50 px-4 py-6 border border-solid border-slate-200 shadow-md rounded-md max-w-[300px] lg:min-w-[280px]`}>
                <IconButton onClick={handleClose}
                            sx={{ position: 'absolute',
                                  top: '0',
                                  right: '30px',
                                ...!isMobile && ({
                                    display: 'none'
                                })
                            }}
                >
                    <CloseIcon sx={{ fontSize: '20px', width: '40px', height: '40px', borderRadius: '100%' }}
                               className={"p-1 bg-white border border-slate-300 rounded-2xl shadow-md"}
                    />
                </IconButton>
                <div className="mb-8">
                    <div className="flex items-center justify-center">
                        <div className="flex items-center text-xl font-bold text-black">{descriptionTitle}</div>
                    </div>
                    {descriptionSubTitle &&
                        <div className="flex items-center justify-center text-md font-bold text-slate-500 mt-4 pl-4">{descriptionSubTitle}</div>
                    }
                </div>

                <Box component={'div'} className={"w-full flex flex-wrap"}>
                    <Box component={'div'} className={"w-full flex flex-wrap items-center"}>

                        {featureStandardDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureStandardDescriptions}
                                                     handleChange={handleChangeStandard}
                                                     value={standardTabValue}
                                                     props={props}
                                                     type="Standard"
                            />
                        )}

                        {featureAdditionalDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureAdditionalDescriptions}
                                                     handleChange={handleChangeAdditional}
                                                     value={additionalTabValue}
                                                     props={props}
                                                     type="Additional Fields"
                            />
                        )}
                    </Box>
                </Box>
            </div>

        </Box>

    )
}


export const NavigationTabsWrapper = (props) => {

    // Navigation Position
    const [navigationPosition, setNavigationPosition] = useState(0);
    const [fixedValue, setFixedValue] = useState(true);

    const headerElement = document.querySelector('header');
    const headerElementHeight = headerElement?.clientHeight;

    useOnWindowScroll(() => setNavigationPosition(window.scrollY));

    useEffect(() => {
        navigationPosition > headerElementHeight ? setFixedValue(false) : setFixedValue(true)

    }, [navigationPosition])

    // Mobile
    const isMobile = useMobile();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            {isMobile ? (
                <>
                    {open ? (
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={open}
                            onClick={handleClose}
                        >
                            <Box component={"div"}
                                 sx={{ position: "relative" }}
                                 className={`flex justify-center`}
                            >
                                <NavigationContent props={props}
                                                   headerElementHeight={headerElementHeight}
                                                   fixedValue={fixedValue}
                                                   handleClose={handleClose}
                                                   isMobile
                                />
                            </Box>

                        </Backdrop>
                    ) : (
                        <Box component={"div"}
                             className={`fixed flex justify-start left-[-2px] bg-slate-200 border-r border border-slate-300 rounded-r-lg z-10 mt-2 shadow-sm`}
                             sx={{
                                 top: `${headerElementHeight}px`,
                                 transition: 'all .2s ease-in-out',
                                 ...!fixedValue && ({
                                     top: '40px',
                                 })
                             }}
                        >
                            <IconButton onClick={() => setOpen(true)}
                                        sx={{ border: 'none', padding: '8px 14px', borderRadius: '10px', color: '#334155' }}
                            >
                                <FiAlignJustify className={"text-[20px]"} />
                                <span className={"text-base text-slate-700 ml-2 font-bold"}>Forms List</span>
                            </IconButton>
                        </Box>
                    )}

                </>
            ) : (
                <NavigationContent props={props}
                                   headerElementHeight={headerElementHeight}
                                   fixedValue={fixedValue}
                />
            )}
        </>
    )
}
