import { useNavigate, useParams } from "react-router-dom";
import { CustomFilter } from "../../../components/filter/CustomFilter";
import { ButtonSidebar } from "../../../components/button/ButtonSidebar";
import React, {useEffect, useRef, useState} from "react";
import { CustomBreadcrumbs } from "../../../components/breadcrumb/CustomBreadcrumbs";
import { Typography } from "@mui/material";
import MyDrawer from "../../../components/drawer/MyDrawer";
import {motion, useInView} from "framer-motion";
import {FiAlignLeft, FiArchive, FiLayers, FiMap, FiTool} from "react-icons/fi";
import { useMobile } from "../../../hooks/useMobile";
import { ListCatalog } from "./ListCatalog";
import MapLeaflet from "../../../components/map/MapLeaflet";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { getLimitedData } from "../../../utils/getLimetedData";
import BackToTop from "../../../components/ScrollTop";
import {FaNetworkWired} from "react-icons/fa";

export const CatalogComp = ({ fetchHook, title, catalogType, isOpen, setIsOpen, limit, dataSelectorKey, bgColorType }) => {

    const queryString = (window.location.search).slice(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);
    const { id } = useParams();
    const [filter, setFilter] = useState(queryString ? queryString : []);
    const [pageLoad, setPageLoad] = useState(false);
    const [networkname, setNetworkName] = useState(null);
    const [list, setList] = useState(true);
    const [myData, setMyData] = useState([]);
    const [buttonLoadIsInView, setButtonLoadIsInView] = useState(false);

    const isMobile = useMobile();
    const itemsPerPage = isMobile ? 10 : limit;

    const navigate = useNavigate();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const { totalItems, isLoading, error, numberItem } = fetchHook(
        id,
        page,
        itemsPerPage,
        filter,
        list,
        queryString
    );

    const data = useSelector((state) => (dataSelectorKey ? state.catalogReducers[dataSelectorKey] : state.catalogReducers.data));

    useEffect(() => {
        if (isInView) {
            setButtonLoadIsInView(true)
        } else {
            setButtonLoadIsInView(false)
        }
    }, []);

    useEffect(() => {
        const datalimited = getLimitedData(data, itemsPerPage, currentPage);
        setMyData(datalimited);
    }, [data, currentPage, itemsPerPage]);

    useEffect(() => {
        const queryString = (window.location.search).slice(1);
        setFilter(queryString);
        setPageLoad(true);
    }, [filter]);

    const loadMoreItems = () => {
        const currentLength = myData.length;
        const nextItems = data?.slice(currentLength, currentLength + itemsPerPage);
        setMyData((prevItems) => [...prevItems, ...nextItems]);
    };

    const handleApplyFilter = (newFilter) => {
        setFilter(newFilter);
    };

    const handleResetFilter = () => {
        setFilter([]);
        setPage(1);
        navigate(window.location.pathname);
    };

    const handleList = () => {
        setList(!list);
        if (list) toast.info("Some data cannot be displayed on the map due to a lack of coordinates.");
    };

    const mylinkData = [
        { id: 1, title: "Choose your Network", link: "catalog", activ: 0, icon: <FaNetworkWired /> },
        { id: 2, title: "Choose Catalog type", link: "catalog", activ: 1, icon: <FiLayers /> },
        { id: 3, title: `${title} catalog`, link: "#" },
    ];

    if (!list) {
        document.querySelector('body').style.overflow = 'hidden';
    } else {
        document.querySelector('body').style.overflow = '';
    }

    return (
        <motion.div
            className=""
            initial={{ opacity: 0 }}
            transition={{ type: "spring", duration: 0.5, ease: "easeIn" }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.article
                id="sectionCatalog"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{type: "spring", duration: 0.5, ease: "easeIn"}}
                className={`min-h-screen bg-slate-50 ${list ? "flex flex-col items-end pt-60 " : "lg:py-0 py-0"} ${ filter ? "lg:pt-60" : "lg:pt-40"} relative flex flex-col`}
            >
                {/* Header */}
                <div className={`w-full flex flex-col fixed top-0 z-10 bg-white`}>
                    <div className={`border-b border-slate-200 lg:h-20 h-32 flex items-center justify-center`}>
                        <div className={`container`}>
                            <div className={"wrapper"}>
                                <div className={`flex lg:flex-row flex-col lg:items-center gap-2 justify-between`}>

                                    <ul className={`flex items-center gap-0 divide-x divide-slate-200`}>
                                        <li className={`flex items-center justify-center ${ isMobile ? "pl-0 pr-4" : "px-4" }`}>
                                            <h2 className={`text-slate-700 text-sm lg:text-md font-medium`}>
                                                About <span className={`font-semibold underline text-blue-500`}>{networkname}</span>  network
                                            </h2>
                                        </li>
                                        <li className="flex items-center justify-center px-4">
                                            <ButtonSidebar
                                                isOpen={isOpen}
                                                setIsOpen={setIsOpen}
                                                catalogType={catalogType}
                                                network={networkname}
                                            />
                                        </li>
                                    </ul>

                                    <div className="order-first">
                                        <CustomBreadcrumbs linkData={mylinkData}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Filter header*/}
                    <div className={`text-slate-500 bg-white shadow-sm`}>
                        <div className={`container`}>
                            <div className={`wrapper relative flex items-center gap-0`}>
                                <CustomFilter
                                    id={id}
                                    handleApplyFilter={handleApplyFilter}
                                    handleResetFilter={handleResetFilter}
                                    totalItems={totalItems}
                                    numberItem={numberItem}
                                    handleList={handleList}
                                    list={list}
                                    setPageLoad={setPageLoad}
                                    totalDataVisible={myData?.length}
                                    myData={myData}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${list ? "container relative" : "absolute w-full min-h-screen"} grow `}>
                    <div className={`${list ? `pb-10 min-h-screen relative flex flex-col gap-4 wrapper` : "w-full"}`}>
                        <div className="flex flex-col items-center gap-10 relative grow lg:pb-0 pb-28">
                            {totalItems !== 0 ? (
                                list ? (
                                    <>
                                        <ListCatalog
                                            drawerOpen={isOpen}
                                            data={myData}
                                            isLoading={isLoading}
                                            error={error}
                                            setPage={setPage}
                                            pageLoad={pageLoad}
                                            setPageLoad={setPageLoad}
                                            totalItems={totalItems}
                                            catalogType={catalogType}
                                        />
                                        { myData?.length < totalItems && (

                                            <div ref={ref} className={`flex flex-col gap-4 items-center`}>
                                                <motion.button
                                                    whileTap={{scale: 0.945}}
                                                    whileHover={{scale: 1.045}}
                                                    transition={{type: "spring", duration: 0.6, ease: "linear"}}
                                                    onClick={loadMoreItems}
                                                    className="border border-solid border-slate-300 rounded-full p-4 w-[150px] text-slate-500"
                                                >
                                                    Load More
                                                </motion.button>
                                                <p className={`text-center text-sm text-slate-500`}>Continue exploring {catalogType} catalogs</p>
                                            </div>

                                        )}
                                    </>
                                ) : (
                                    <MapLeaflet data={data} catalogType={catalogType} list={list}/>
                                )
                            ) : (
                                <motion.div
                                    className="flex items-center justify-center min-h-[500px]"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{type: "spring", duration: 0.5, ease: "linear"}}
                                >
                                    <Typography component="p" variant="subtitle2"
                                                className="flex gap-2 items-center justify-center flex-col">
                                      <span className="text-blue-400 text-4xl">
                                        <FiArchive/>
                                      </span>No results
                                    </Typography>
                                </motion.div>
                            )}
                        </div>
                    </div>
                </div>

                {/* BUTTON TO DISPLAY MAP OR LIST*/}
                { !buttonLoadIsInView &&
                    <motion.button
                        onClick={handleList}
                        className="flex items-center gap-4 flex-row-reverse text-slate-50 p-4 rounded-full fixed bottom-4 left-1/2 transform -translate-x-1/2 z-10 bg-black"

                        title={list ? "Map" : "List"}

                        initial={{ opacity:0}}
                        animate={{ opacity:1}}
                        exit={{ opacity:0}}

                        transition={{ type: "spring", duration: 0.5, ease: "easeIn" }}
                    >
                        <motion.span
                            className={` flex items-center gap-2 font-medium rounded-full border border-solid border-slate-300 text-slate-50 p-2 text-xs `}

                            whileHover={{scale: 1.0945}}
                            whileTap={{scale: .9}}
                            transition={{type: "spring", duration: .5, ease: "linear"}}

                        >
                            {list ? <FiMap/> : <FiAlignLeft/>}
                        </motion.span>
                        {list ? "Display map" : "Display List"}
                    </motion.button>
                }


                <BackToTop />
            </motion.article>

            <MyDrawer
                isOpen={isOpen}
                networkId={id}
                setIsOpen={setIsOpen}
                setNetworkName={setNetworkName}
                buttonClose
                variantDrawer="temporary"
                closeDrawer={true}
            />
        </motion.div>
    );
}
